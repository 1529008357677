const common = {
    registerUserError: {
        english: 'Error Registering User. Please refresh and try again',
        sinhala:
            'පරිශීලකයා ලියාපදිංචි කිරීමේදී දෝෂයකි. කරුණාකර නැවුම් කර නැවත උත්සාහ කරන්න',
        tamil:
            'பயனரைப் பதிவு செய்வதில் பிழை. புதுப்பித்து மீண்டும் முயற்சிக்கவும்',
    },
    fetchRegisteredUsersError: {
        english: 'Error occurred when fetching the registered users',
        sinhala: 'ලියාපදිංචි පරිශීලකයින් ලබා ගැනීමේදී දෝෂයක් ඇතිවිය',
        tamil: 'பதிவுசெய்த பயனர்களைப் பெறும்போது பிழை ஏற்பட்டது',
    },
    otpValidationFailedError: {
        english: 'OTP validation failed',
        sinhala: 'OTP වලංගු කිරීම අසාර්ථක විය',
        tamil: 'OTP சரிபார்ப்பு தோல்வியுற்றது',
    },
    sendingOtpFailedError: {
        english: 'Sending OTP to mobile number failed',
        sinhala: 'ජංගම දුරකථන අංකයට OTP යැවීම අසාර්ථක විය',
        tamil: 'மொபைல் எண்ணுக்கு OTP அனுப்புவது தோல்வியுற்றது',
    },
    fetchCerificateError: {
        english: 'To download the vaccination against the COVID-19 certificate, please use the same mobile number that you provided to register for the vaccination process.',
        sinhala: 'COVID-19 එරෙහිව එන්නත් කිරිමේ සහතිකය බාගත කිරීම සඳහා, කරුණාකර ඔබ එන්නත් කිරීමේ ක්‍රියාවලිය සඳහා ලියාපදිංචි වීමට ලබා දුන් ජංගම දුරකථන අංකයම භාවිතා කරන්න.',
        tamil: 'COVID-19 க்கு எதிராக தடுப்பூசி சான்றிதழைப் பதிவிறக்க, தடுப்பூசி செயல்முறைக்கு பதிவு செய்ய நீங்கள் வழங்கிய மொபைல் எண்ணைப் பயன்படுத்தவும்.',
    },
    certificateNotFound: {
        english: 'Certificate not found for this user',
        sinhala: 'මෙම පරිශීලකයා සඳහා සහතිකය හමු නොවීය',
        tamil: 'இந்த பயனருக்கு சான்றிதழ் கிடைக்கவில்லை',
    },
    recordAlreadyExist: {
        english: 'You already completed your vaccination.',
        sinhala: 'ඔබ දැනටමත් ඔබේ එන්නත සම්පූර්ණ කර ඇත.',
        tamil: 'நீங்கள் ஏற்கனவே உங்கள் தடுப்பூசி முடித்துவிட்டீர்கள்.',
    },
    invalidNICorMobile: {
        english: 'NIC or mobile number invalid',
        sinhala: 'NIC හෝ ජංගම දුරකථන අංකය අවලංගුය',
        tamil: 'நிக் அல்லது மொபைல் எண் தவறானது',
    },
    sessionFull: {
        english: 'The vaccine session already full.',
        sinhala: 'එන්නත් සැසිය දැනටමත් පිරී ඇත.',
        tamil: 'தடுப்பூசி அமர்வு ஏற்கனவே நிரம்பியுள்ளது.',
    },
    noSession: {
        english: 'Currently, we do not have any active sessions.',
        sinhala: 'දැනට, අපට කිසිදු ක්‍රියාකාරී සැසි නොමැත.',
        tamil: 'பதிவு ஏற்கனவே உள்ளதுதற்போது, ​​எங்களிடம் செயலில் எந்த அமர்வுகளும் இல்லை.',
    },
    alreadyVaccinated: {
        english: 'You have already completed the vaccination process and you do not need to register again for the second dose of vaccination.',
        sinhala: 'ඔබ දැනටමත් එන්නත් කිරීමේ ක්‍රියාවලිය සම්පූර්ණ කර ඇති අතර දෙවන එන්නත සඳහා නැවත ලියාපදිංචි වීමට අවශ්‍ය නොවේ.',
        tamil: 'நீங்கள் ஏற்கனவே தடுப்பூசி செயல்முறையை முடித்துவிட்டீர்கள், தடுப்பூசியின் இரண்டாவது டோஸுக்கு நீங்கள் மீண்டும் பதிவு செய்ய தேவையில்லை.',
    },
    nicAlreadyAttachedPart1: {
        english: 'Your NIC number is registered with mobile number ',
        sinhala: 'ඔබගේ ජාතික හැඳුනුම්පත් අංකය ',
        tamil: 'உங்கள் என்ஐசி எண் மொபைல் எண் ',
    },
    nicAlreadyAttachedPart2: {
        english: '. Please use the same mobile number to register again.',
        sinhala: ' ජංගම දුරකථන අංකය සමඟ ලියාපදිංචි වී ඇත. නැවත ලියාපදිංචි වීමට කරුණාකර එකම ජංගම දුරකථන අංකය භාවිතා කරන්න.',
        tamil: ' உடன் பதிவு செய்யப்பட்டுள்ளது. மீண்டும் பதிவு செய்ய அதே மொபைல் எண்ணைப் பயன்படுத்தவும்.',
    },
    alreadyRegistered: {
        english: 'User already registered.',
        sinhala: 'පරිශීලකයා දැනටමත් ලියාපදිංචි වී ඇත.',
        tamil: 'பயனர் ஏற்கனவே பதிவுசெய்துள்ளார்',
    },
    InvalidDate: {
        english: 'Should be a valid date.',
        sinhala: 'වලංගු දිනයක් විය යුතුය.',
        tamil: 'சரியான தேதியாக இருக்க வேண்டும்',
    },
    notEligibleForCert: {
        english: 'not eligible for cert',
        sinhala: 'වලංගු දිනයක් විය යුතුය.',
        tamil: 'சரியான தேதியாக இருக்க வேண்டும்',
    },
    nicAlreadyAttachedCertPart1: {
        english: 'not eligible for cert',
        sinhala: 'වලංගු දිනයක් විය යුතුය.',
        tamil: 'சரியான தேதியாக இருக்க வேண்டும்',
    },
    nicAlreadyAttachedCertPart2: {
        english: 'not eligible for cert',
        sinhala: 'වලංගු දිනයක් විය යුතුය.',
        tamil: 'சரியான தேதியாக இருக்க வேண்டும்',
    }
};

export default common;
