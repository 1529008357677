import React, { Component } from 'react'
//import QrReader from 'react-qr-reader'

class Test extends Component {
  state = {
    result: JSON.parse('{"@context":["https://www.w3.org/2018/credentials/v1","https://cowin.gov.in/credentials/vaccination/v1"],"type":["VerifiableCredential","ProofOfVaccinationCredential"],"credentialSubject":{"type":"Person","id":"","refId":"1613102556461","name":"Hiranya","gender":"Male","age":"34","nationality":"Other","address":{"streetAddress":"Madinnagoda","streetAddress2":"N/A","district":"Colombo","city":"","addressRegion":"Western","addressCountry":"IN","postalCode":12345}},"issuer":"https://cowin.gov.in/","issuanceDate":"2021-02-12T04:09:35.444Z","evidence":[{"id":"https://cowin.gov.in/vaccine/735795393","feedbackUrl":"https://cowin.gov.in/?735795393","infoUrl":"https://cowin.gov.in/?735795393","certificateId":"735795393","type":["Vaccination"],"batch":"","vaccine":"astrazeneca","manufacturer":"India","date":"2021-02-12T04:09:04.868Z","effectiveStart":"2021-02-12","effectiveUntil":"2022-02-12","dose":1,"totalDoses":1,"verifier":{"name":"Irosha"},"facility":{"name":"Ragama Teaching Hospital","address":{"streetAddress":"Ragama","streetAddress2":"Sri Lanka","district":"Gampaha","city":"","addressRegion":"Western","addressCountry":"IN","postalCode":12345}}}],"nonTransferable":"true","proof":{"type":"RsaSignature2018","created":"2021-02-12T04:09:35Z","verificationMethod":"did:india","proofPurpose":"assertionMethod","jws":"eyJhbGciOiJQUzI1NiIsImI2NCI6ZmFsc2UsImNyaXQiOlsiYjY0Il19..B5yJH-IZogLYl0HYXd-roV3dtt07oNs3znN9f_4kJbFHnWulG_YvGQIClVPyrtBjUQRs4kUk2BwUvV0IG2ktR29S3f1I563f6VeriNovfBQdz_CfsRArcj6C2--IQk2kf-_QrZW3_vFzx8HSkVifnwscmNFnPub2yX5LPTumV21s8hex7SPweJHcMiat-oCshVd9ZMXz3SdtfCz-mBQri7TS0rzC9TQcTo23T-sf45qO0pEF9Un9nV3iXPfQJdHdjZR_UVEA8nZKHTnnBLqRGVpOVMZSmZnA1LyoMvxkUx8TxO8a3X2nUa18TAIh6LFFRUDm5RM_SgQuhIujfqlkUg"}}')
  }

  handleScan = data => {
    if (data) {
       // console.log(data, "dataaaaaaaaaaaa")
      this.setState({
        result: data
      })
    }
  }
  handleError = err => {
    console.error(err)
  }
  render() {
    return (
      <div>
          {/* { console.log(this.state.result, "dataaaaaaaaaaaa")} */}
        {/* <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '50%' }}
        /> */}
      </div>
    )
  }
}

export default Test;