import {
    FETCH_CENTERS_SUCCESS,
    FETCH_CENTERS_FAILED,
    SUBMIT_FORM_REGISTRATION_SUCCESS,
    SUBMIT_FORM_REGISTRATION_FAILED,
    FETCH_VACCINE_TYPE,
    FETCH_VACCINE_TYPE_SUCCESS,
    FETCH_VACCINE_TYPE_FAILED,
} from '../actions/types';

const initialState = {
    centers: {
        loading: true,
        error: undefined,
        data: [],
    },
    confirmation: {
        loading: true,
        error: undefined,
        appointmentNumber: '',
        date: '',
        timeslot: '',
        location: '',
        name: '',
        nic: '',
        errorDescription: ''
    },
    vaccineTypes: {
        loading: false,
        vaccine: [],
        error: undefined,
    }
};

const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CENTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                centers: {
                    ...state.centers,
                    loading: false,
                    data: action.payload.organisationUnits.sort((a, b) =>
                        a.displayName > b.displayName ? 1 : -1
                    ),
                },
            };
        case FETCH_CENTERS_FAILED:
            return {
                ...state,
                centers: {
                    ...state.centers,
                    loading: false,
                    error:
                        'Error occurred when fetching the vaccination centers. Please refresh and try again',
                },
            };
        case SUBMIT_FORM_REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: false,
                confirmation: {
                    ...state.confirmation,
                    loading: false,
                    appointmentNumber: action.payload.appointmentNo,
                    date: action.payload.date,
                    timeslot: action.payload.timeslot,
                    location: action.payload.location,
                    name: action.payload.name,
                    nic: action.payload.nic,
                    locationName: action.payload.vaccineLocation,
                },
            };
        case SUBMIT_FORM_REGISTRATION_FAILED:
            return {
                ...state,
                confirmation: {
                    ...state.confirmation,
                    loading: false,
                    error: 'registerUserError',
                    errorDescription: action.payload?.response?.data?.errorDescription

                },
            };

        /** Fetch vaccine typs */
        case FETCH_VACCINE_TYPE:
            return {
                ...state,
                loading: true,
            };
        case FETCH_VACCINE_TYPE_SUCCESS:
            let vaccineTypeObjList = action.payload.data;
            let dataArray = []
            for (let id in vaccineTypeObjList) {
                let obj = vaccineTypeObjList[id];
                obj['id'] = Number.parseInt(id);
                dataArray.push(obj);
            }
            return {
                ...state,
                loading: false,
                vaccineTypes: {
                    ...state.vaccineTypes,
                    vaccine: dataArray,
                    error: undefined,
                },
            };
        case FETCH_VACCINE_TYPE_FAILED:
            return {
                ...state,
                vaccineTypes: {
                    ...state.vaccineTypes,
                    vaccine: [],
                    error: "",
                },
            };
        default:
            return state;
    }
};

export default registrationReducer;
