import { CHANGE_PRIVACY_POLICY, CHANGE_RECAPCHA_STATUS } from '../actions/types';

const initialState = {
    isAgreePrivacy: false,
    isCheckedRecapcha: false,
};

const privacyPolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PRIVACY_POLICY:
            sessionStorage.setItem("isAgreePrivacy", action.payload)
            return {
                ...state,
                isAgreePrivacy: action.payload,
            };
        case CHANGE_RECAPCHA_STATUS:
            return {
                ...state,
                isCheckedRecapcha: action.payload,
            };
        default:
            //sessionStorage.setItem("isAgreePrivacy", state.isAgreePrivacy)
            return state;
    }
};

export default privacyPolicyReducer;