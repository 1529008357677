import {
    FETCH_CENTERS,
    FETCH_CENTERS_SUCCESS,
    FETCH_CENTERS_FAILED,
    SUBMIT_FORM_REGISTRATION,
    SUBMIT_FORM_REGISTRATION_SUCCESS,
    SUBMIT_FORM_REGISTRATION_FAILED,
    FETCH_VACCINE_TYPE,
    FETCH_VACCINE_TYPE_SUCCESS,
    FETCH_VACCINE_TYPE_FAILED,
} from './types';

export const fetchCenters = payload => {
    return { type: FETCH_CENTERS, payload };
};

export const fetchCentersSuccess = payload => {
    return { type: FETCH_CENTERS_SUCCESS, payload };
};

export const fetchCentersFailed = payload => {
    return { type: FETCH_CENTERS_FAILED, payload };
};

export const submitFormRegistration = payload => {
    return { type: SUBMIT_FORM_REGISTRATION, payload };
};

export const submitFormRegistrationSuccess = payload => {
    return { type: SUBMIT_FORM_REGISTRATION_SUCCESS, payload };
};

export const submitFormRegistrationFailed = payload => {
    return { type: SUBMIT_FORM_REGISTRATION_FAILED, payload };
};

/** Fetch vaccine type */
export const fetchVaccineType = payload => {
    return { type: FETCH_VACCINE_TYPE, payload };
};

export const fetchVaccineTypeSuccess = payload => {
    return { type: FETCH_VACCINE_TYPE_SUCCESS, payload };
};

export const fetchVaccineTypeFailed = payload => {
    return { type: FETCH_VACCINE_TYPE_FAILED, payload };
};
