import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    // root: {
       // padding: theme.spacing(4),
       // bottom: 0,
      //  width: '100%',
       // position: 'relative',
      //  position: 'absolute',
      //  width: '-webkit-fill-available',
  //  } 
    root : {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        //backgroundColor : "green",
        height : 100,
        alignItems: "end",
        padding: '12px',
        display: 'block',
    },
    mainDiv :{
        minWidth: "45%",
        fontSize: 16,
        marginTop: "1rem",
    },
    flexGrow: {
        flexGrow: 1,
    },
    img: {
        maxWidth: "60px",
        height: "23.45px",
    },
    logoWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bottom: '0',
    },
    footerDiv : {
        background: '#f6f6f6',
        padding: '15px',
        textAlign: 'center',
        color: '#fff',
        fontSize: '11px',
        fontFamily: 'Raleway, sans-serif',
        paddingLeft: '0',
        paddingRight: '0',
        width: '100%',
        float: 'left',
        display: 'flex',
        justifyContent: 'center',
    },
    footerText : {
        color: 'black',
        cursor: "pointer",
    },
    alignContents: {
        justifyContent: "center",
        display: "flex",
    },
    privacyPolicy : {
        color: '#807e7e',
        cursor: "pointer",
    }
}));

const Footer = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    const handleChangePrivacyPolicy = () => {
        //history.push("/privacy");
        const win = window.open("/privacy", "_blank");
        win.focus();
    }

    const handleOpenIctaSite = () => {
        const win = window.open("https://www.icta.lk/", "_blank");
        win.focus();
    }

    return (
        <div {...rest} className={clsx(classes.root, className)}>
           {/*  <div>
            <div className={classes.footerDiv}>
                <p className={classes.footerText}>© 2021 ICTA. All Rights Reserved. </p>
                
            </div>
            <div>
            <Link
                    onClick={handleChangePrivacyPolicy}
                    className={classes.footerText}
                >   Privacy Policy
                    </Link> 
            </div>
            <div className={classes.logoWrapper}>
                <img
                    className={classes.img}
                    alt="Logo"
                    src="/images/logos/icta-full-logo.png"
                />
            </div>
            </div> */}
            <div className={classes.mainDiv}>
                <Grid 
                    container
                    justify="center"
                    alignItems="center"
                    spacing={3}>
                    <Grid item xs={12} sm={12} className={classes.alignContents}>
                        <div className={classes.privacyPolicy} >Copyright © 2021 <Link className={classes.privacyPolicy} onClick={handleOpenIctaSite}>ICTA</Link>. All rights reserved. </div>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.alignContents}>
                        <Link
                                onClick={handleChangePrivacyPolicy}
                                className={classes.footerText}
                            >   Privacy Policy
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.alignContents}>
                       {/*  <div className={classes.logoWrapper}> */}
                            <img
                                className={classes.img}
                                alt="Logo"
                                src="/images/logos/icta-full-logo.png"
                            />
                       {/*  </div> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
