import axios from 'axios';

export default {
    location: {
        getDistricts: payload =>
            axios.get(`/districts`).then(res => res),
        
        getMohs: payload =>
            axios.get(`/mohcenters/${payload}`).then(res => res),

        getCenters: payload =>
            axios.get(`/locationlist/${payload.mohId}/${payload.dose}/${payload.vaccineTypeId}`).then(res => res),
    },
};
