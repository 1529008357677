import Test from 'qrScanner';
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Minimal as MinimalLayout } from './layouts';

import {
    SignIn as SignInView,
    Registration as RegistrationView,
    NotFound as NotFoundView,
    RegistrationList as RegistrationListView,
    CertificateView,
    PrivacyPolicy,
    RequestCertificate,
} from './views';

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/sign-in" />
            <Route path="/health">
                <h3>Hey There!!! The App is Healthy</h3>
            </Route>
            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/sign-in"
            />
            <RouteWithLayout
                component={RegistrationView}
                exact
                layout={MinimalLayout}
                path="/registration"
            />
            <RouteWithLayout
                component={RegistrationListView}
                exact
                layout={MinimalLayout}
                path="/registration-list"
            />
            <RouteWithLayout
                component={CertificateView}
                exact
                layout={MinimalLayout}
                path="/view-certificate"
            />
            <RouteWithLayout
                component={Test}
                exact
                layout={MinimalLayout}
                path="/qrCode"
            />
            <RouteWithLayout
                component={PrivacyPolicy}
                exact
                layout={MinimalLayout}
                path="/privacy"
            />
            <RouteWithLayout
                component={RequestCertificate}
                exact
                layout={MinimalLayout}
                path="/request-certificate"
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
