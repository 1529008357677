import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import languageText from 'languages/privacyPolicy';

const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
     defenetion: {
        marginLeft: '27px',
        lineHeight: 1.8,
     },
     contentWraper:{
        lineHeight: 1.6,
        fontSize:18,
     }
}));

const PrivacyPolicy = ({language}) => {
    
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h1" align="center">
                    {languageText.privacyPolicyHeader[language]}
                    <br></br>
                    <hr></hr>
                </Typography>
                <div className={classes.contentWraper}>
                    <br></br>
                    <div>Last updated: 18th May 2021</div>
                    <br></br>
                    <div>Welcome to the Privacy Policy of the <b>Sri Lanka COVID-19 Vaccination Program - Citizen
Registration Portal</b> (hereinafter collectively referred to as "Citizen Portal" "us" "we" or "our"). </div>
                    <br></br>
                    <div>
                    This Privacy Policy details the manner in which we collect, use and protect Your Personal Data
provided by You in the course of Your registration to Citizen Portal to manage COVID-19
vaccination appointments, Your profile management, Vaccination certificate generation,
Vaccination certificate authentication and other related services that will be provided to You as
a person living in Sri Lanka. This Privacy Policy applies regardless of the type of interface You
use to access our Services.
                    </div>
                    <br></br>
                    <div>
                    We are committed to protecting your Personal Data and Your privacy. To ensure that You can
make informed decisions and feel confident about sharing certain personal information with
Us, please read this Privacy Policy to understand how We collect and process Your Personal
Information and for what purposes, and the choices You have concerning how Your Personal
Information is collected and used by Us.
This Privacy Policy sets out and defines the privacy policies and procedures of the Citizen
Portal of Sri Lanka, including but not limited to the collection, use and disclosure, of your
personal data through the use of Citizen Portal Web Application via mobile devices, tablets,
consumer electronic devices (also referred to as the “Service”).
                    </div>
                    <br></br>
                    <div>
                    This Privacy Policy further provides you with information regarding your privacy and data
protection rights available under the existing legal framework in Sri Lanka and shall be updated
from time to time.
                    </div>
                    <br></br>
                    <div>
                    By using this Service, You agree to the Processing of Your Personal data in accordance with
this Privacy Policy.
                    </div>
                   
                    <br></br>
                    <h3>1. Interpretation and Definitions</h3>
                    <br></br>
                    <h3>1.1 Interpretation</h3>
                    <br></br>
                    <div>The words of which the initial letter is capitalized have meanings defined under the following
conditions.</div>
                    <br></br>
                    <div>
                    The following definitions shall have the same meaning regardless of whether they appear in
singular or in plural.
                    </div>
                    <br></br>
                    <h3>1.2 Definitions</h3>
                    <br></br>
                    <div>
                    For the purposes of this Privacy Policy;
                    </div>
                    <br></br>
                    <ul className={classes.defenetion}>
                        <li><div><b>Account </b>means a unique account created for You to access the Service or parts of the Service.</div></li>
                        <li><div><b>Cookies </b>are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</div></li>
                        <li><div><b>Country </b>means the Democratic Socialist Republic of Sri Lanka.</div></li>
                        <li><div><b>Other Government Parties </b>means relevant governmental ministries, departments and
statutory bodies of the Democratic Socialist Republic of Sri Lanka including but not limited
to the Ministry of Health and Information and Communication Technology Agency of Sri
Lanka.</div></li>
                        <li><div><b>Personal Data </b>means personally identifiable information requested for or gathered as
Registration Data.</div></li>
                        <li><div><b>Registration Data </b>is any information that is required for the access and use of the Service
which may also include Personal Data. </div></li>
                       
                        <li><div><b>Services </b>means and alternatively refers to provision of access to the Citizen Portal via
which Vaccination appointment management, Citizen's profile management, Vaccination
certificate generation, Vaccination certificate authentication and other services will be
provided to the people living in Sri Lanka through the web at the Website via any
electronic device.</div></li>
                        <li><div><b>Citizen Portal Web Application </b>(at times referred to as "We", "Us" or "Our" in this
Agreement) is facilitated by ICTA (in collaboration with its stakeholders), on behalf of the
Government of the Democratic Socialist Republic of Sri Lanka.</div></li>
                        <li><div><b>Usage Data </b>refers to data collected automatically, either generated by the use of the
Service or from the Service infrastructure itself (for example, the duration of a page visit).</div></li>
                        <li><div><b>Website </b>refers to <a href="http://vaccine.covid19.gov.lk/">http://vaccine.covid19.gov.lk</a> and <a href="https://cert.covid19.gov.lk/">https://cert.covid19.gov.lk</a></div></li>
                        <li><div><b>You </b>or <b>Your </b>r means the individual accessing or using the Service, or the company, or other
legal entity on behalf of which such individual is accessing or using the Service, as
applicable.</div></li>
                    </ul>
                    <br></br>
                    <h3>2. Collecting and Using Your Personal Data</h3>
                    <br></br>
                    <h3>2.1 Types of Data Collected</h3>
                    <br></br>
                    <h4>2.1.1 Personal Data</h4>
                    <br></br>
                    <div>In order to use the Service, We may request from You to provide Us with certain personally
identifiable information or Registration Data that can be used to contact or personally identify
You at the point of registering for the Service or any time thereafter. Personally identifiable
information requested for or gathered under Registration Data may include, but is not limited
to:</div>
                    <br></br>
                    <ul className={classes.defenetion}>
                        <li><div>Your name as per the Sri Lankan National Identity Card or Passport</div></li>
                        <li><div>Sri Lankan National Identity Card number or Passport number</div></li>
                        <li><div>Email address</div></li>
                        <li><div>Contact Mobile Telephone number</div></li>
                        <li><div>Health information (including but not limited to your medical history, allergies and
comorbidities)</div></li>
                        <li><div>Residential Address</div></li>
                        <li><div>Date of birth</div></li>
                        <li><div>Gender</div></li>
                    </ul>
                    <br></br>
                    <h4>2.1.2 Information We Receive or Collect from Third Parties</h4>
                    <br></br>
                    <div>We may receive or collect information about You from third parties and combine and store it
on Our servers with other information We may have already received or collected from You.
These third parties include Government / Private sector organizations, including hospitals,
Ministries, Departments, Provincial or Local Authorities and other connected websites or
applications that may receive or collect information about You while facilitating the Services
defined above. We are not responsible for, and will assume no liability, if a third party or other
entity collects, uses, or shares any information about You in violation of its own privacy policy
or any applicable laws, rules, or agreements.</div>
                    <br></br>
                    <h4>2.1.3 SMS Notifications</h4>
                    <br></br>
                    <div>We may send You SMS notifications from time to time in order to update You on your
appointments, availability of vaccination certificates and related records. To ensure You
receive proper notifications, We may collect certain information as may be specified from time
to time.</div>
                    <br></br>
                    <h4>2.1.4 Our Use of Cookies</h4>
                    <br></br>
                    <div>
                    Citizen Portal uses a technology that is commonly known as "Cookies." A cookie is a file Our
server writes to Your hard drive that contains an alphanumeric identifier. 
                    </div>
                    <br></br>
                    <div>We use the identifier in a cookie to help Us manage and report on Your interaction with the
Service. Through cookies, We are able to collect information that We use to improve the
Service, keep count of return visits to Our portal, collect and report on aggregate statistical
information, authenticate Your login credentials, or manage multiple instances of the Service
in a single browser. We may also collect other data such as the page or site that referred You to
the Service, the date and time You visited the Service, and Your current IP address. The cookies
We place on Your hard drive are known as "first-party cookies."</div>
                    <br></br>
                    <div>
                    We use both session cookies and persistent cookies. A session cookie expires when You close
your browser. A persistent cookie remains on Your hard drive for an extended period of time.
You may be able to remove persistent cookies by following directions provided in Your
browser's "help" section. If You do not accept first-party cookies You may still use our Service,
but Your ability to use some areas of Our website, and the ability to stay logged in, will be
limited.  
                    </div>
                    <br></br>
                    <h3>
                    2.2 Use of Your Personal Data
                    </h3>
                    <br></br>
                    <div>The Citizen Portal of Sri Lanka may use your Personal Data for the following purposes:</div>
                    <br></br>

                    <ul className={classes.defenetion}>
                        <li><b>To establish the Services you have registered for;</b></li>
                        <li><b>To verify and validate your identity when required;</b></li>
                        <li><b>To provide You with a digitally verifiable COVID-19 Immunization Certificate;</b></li>
                        <li><b>To assist You in responding to Your complaints or feedback;</b></li>
                        <li><b>To ensure safety and security of You and the Services provided via Citizen Portal;</b></li>
                        <li><b>For the Effective management of the COVID-19 vaccination Rollout;</b></li>
                        <li><b>To provide and maintain our Service, </b> including monitoring the usage of our Service.</li>
                        <li><b>To manage Your Account; </b>to manage Your registration as a user of the Service. The
Personal Data You provide can give You access to different functionalities of the Service
that are available to You as a registered user.</li>
                        <li><b>To contact You: </b>To contact You by email, telephone calls, SMS, or other equivalent forms
of electronic communication, provide updates or informative communications related to
the functionalities, services, including security updates, when necessary or reasonable for
their implementation.</li>
                    </ul>
                    <br></br>
                    <div>
                    We may also share Your personal information with Government Parties and other entities
including non-government entities who are specifically authorized to carry out the Services
defined above for the purpose of effectively managing the COVID-19 vaccination Rollout, in
which case We will require such parties to comply with this Privacy Policy.
                    </div>
                    <br></br>
                    <h3>2.3 Retention of Your Personal Data</h3>
                    <br></br>
                    <div>
                    Citizen Portal will retain Your Personal Data for as long as it may be necessary to carry out the
purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent
necessary to comply with legal obligations (for example, if We are required to retain your data
to comply with applicable laws), resolve disputes, and enforce our legal obligations and
policies.
                    </div>
                    <br></br>
                    <h3>2.4 Transfer of Your Personal Data</h3>
                    <br></br>
                    <div>
                    Your information, including Personal Data, is processed by Citizen Portal. Your consent to this
Privacy Policy followed by Your submission of such information represents Your agreement
to that transfer and its use.
                    </div>
                    <br></br>
                    <div>
                    Citizen Portal will take all steps reasonably possible to ensure that Your data is treated securely
and in accordance with this Privacy Policy.
                    </div>
                    <br></br>
                    <h3>2.5 Storage of Your Personal Data</h3>
                    <br></br>
                    <div>
                    Your Personal Data collected by the Citizen Portal shall be hosted on Lanka Government
Cloud 2.0 (LGC 2.0), the central and common hosting environment utilized by the Government
of Democratic Socialist Republic of Sri Lanka and We ensure that Your information collected
on this LGC 2.0 shall at all times be stored within the territory and control of the facilitators of
the Citizen Portal ie; the Information and Communication Technology Agency of Sri Lanka
and the Government of the Democratic Socialist Republic of Sri Lanka.
                    </div>
                    <br></br>
                    <h3>2.6 Disclosure of Your Personal Data</h3>
                    <br></br>
                    <div>
                    For the effective management of COVID-19 vaccination rollout, Citizen Portal may be required
to disclose Your Personal Data to other Government entities, including Health Authorities and
non-government entities who are specifically authorized to carry out the Services defined above
in order to attend, manage and respond to the COVID-19 vaccination rollout in Sri Lanka.
Under certain circumstances, Citizen Portal may require you to disclose Your Personal Data
under provisions of written law or in response to valid requests by public authorities, pursuant
to obligations under written law.
                    </div>
                    <br></br>
                    <h3>2.7 Other legal requirements</h3>
                    <br></br>
                    <div>Citizen Portal may disclose Your Personal Data in the good faith belief that such action is
necessary to:</div>
                    <br></br>
                    <ul className={classes.defenetion}>
                        <li><div>Ensure compliance with legal and regulatory requirements in the interest of public health,
public safety or any other obligation imposed by written law;</div></li>
                        <li><div>Maintain records on the vaccination rollout by government institution, agencies or
authorities;</div></li>
                        <li><div>Ensure performance of the Services;</div></li>
                        <li><div>Validate your information, as and when required;</div></li>
                        <li><div>Protect against legal liability</div></li>
                    </ul>
                    <br></br>
                    <h3>2.8 Security of Your Personal Data</h3>
                    <br></br>
                    <div>
                    The security of Your Personal Data is paramount to Us. We may share necessary data with
other Government institutions, to serve you in a most efficient and effective way, unless such
sharing is prohibited by law. We will NOT share your personal data with non-Government
entities, except where such entities have been authorized to carry out specific Government
services. To safeguard the confidentiality and security of your Personal data to the best of Our
abilities, all electronic storage and transmission of Personal data is secured with reasonable
technical, administrative and physical measures. When your personal information is shared, we
will take a reasonable approach to prevent the unauthorized use and disclosure of personal
information.
                    </div>
                    <br></br>
                    <div>
                    Please note, however, that while we attempt to safeguard your personal information, no method
of transmitting or storing electronic information is ever completely secure, and thus we make
no warranty, express, implied, or otherwise, that your information will never be accessed, used
or released in a manner that is inconsistent with this Privacy Policy.
We do not accept any responsibility whatsoever for any unauthorized access or loss of Personal
Information that is beyond our control.
                    </div>
                    <br></br>
                    <h3>3. Changes to this Privacy Policy</h3>
                    <br></br>
                    <div>We may update this Privacy Policy from time to time. Such updates shall be posted on the
Citizen portal as and when this Privacy Policy is updated. If any changes to this Privacy Policy
is made, we will notify you by including a <b>"NEWLY UPDATED"</b> label with the <b>"PRIVACY
POLICY"</b> link on our Services after material changes are made.</div>

                        <br></br>
                        <div>
                        We encourage you to review this Policy periodically for any changes.
                        </div>
                        <br></br>
                        
                        <h3>Contact Us</h3>
                        <br></br>
                        <div>
                        You have the right to verify and access Personal Data held by us about you and correct any
inaccuracies.
                        </div>
                        <br></br>
                        <div>We will take reasonable steps to ensure that your Personal data is accurate. To help us do this,
please notify us of any changes to your Personal Data as and when it occurs. </div>
                        <br></br>
                        <div>
                        If you have any questions about this Privacy Policy, You can contact us:
                        </div>
                        <br></br>
                        <ul className={classes.defenetion}>
                            <li><div>By email: info@icta.lk</div></li>
                        </ul>
                        </div>

            </Paper>
        </main>
    </div>
    );
};

function mapStateToProps({ common }) {
    let { language } = common;

    return {
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, {})(PrivacyPolicy);
