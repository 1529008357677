import { combineReducers } from 'redux';
import signinReducer from './signin';
import registrationListReducer from './registrationList';
import registrationReducer from './registration';
import commonReducer from './common';
import certificateReducer from './certificate';
import privacyPolicyReducer from './privacyPolicy';
import tokenReducer from './token';
import vaccineLocationsReducer from './vaccineCenters';

/**
 * Redux Store
 */
export default combineReducers({
    signin: signinReducer,
    registrationList: registrationListReducer,
    registration: registrationReducer,
    common: commonReducer,
    certificate: certificateReducer,
    privacyPolicy: privacyPolicyReducer,
    token: tokenReducer,
    vaccineLocation: vaccineLocationsReducer,
});
