/**
 * ================== Sign in Related Tasks ============================
 */

export const REQUEST_OTP = 'REQUEST_OTP';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const REQUEST_OTP_FAILED = 'REQUEST_OTP_FAILED';
export const OTP_ENTERED = 'OTP_ENTERED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
/**
 * ================== Sign in Related Tasks Ends ============================
 */

/**
 * ================== Registration List Related Tasks ============================
 */

export const FETCH_REGISTERED_USERS = 'FETCH_REGISTERED_USERS';
export const FETCH_REGISTERED_USERS_SUCCESS = 'FETCH_REGISTERED_USERS_SUCCESS';
export const FETCH_REGISTERED_USERS_FAILED = 'FETCH_REGISTERED_USERS_FAILED';

/**
 * ================== Registration List Related Tasks Ends ============================
 */


/**
 * ================== Certificate Related Tasks ============================
 */


export const FETCH_CERTIFICATE = 'FETCH_CERTIFICATE';
export const FETCH_CERTIFICATE_SUCCESS = 'FETCH_CERTIFICATE_SUCCESS';
export const FETCH_CERTIFICATE_FAILED = 'FETCH_CERTIFICATE_FAILED';

/**
 * ==================  Certificate Related Tasks Ends ============================
 */

/**
 * ================== Registration Related Tasks ============================
 */

export const FETCH_CENTERS = 'FETCH_CENTERS';
export const FETCH_CENTERS_SUCCESS = 'FETCH_CENTERS_SUCCESS';
export const FETCH_CENTERS_FAILED = 'FETCH_CENTERS_FAILED';
export const SUBMIT_FORM_REGISTRATION = 'SUBMIT_FORM_REGISTRATION';
export const SUBMIT_FORM_REGISTRATION_SUCCESS =
    'SUBMIT_FORM_REGISTRATION_SUCCESS';
export const SUBMIT_FORM_REGISTRATION_FAILED =
    'SUBMIT_FORM_REGISTRATION_FAILED';

/**
 * ================== Registration Related Tasks Ends ============================
 */

/**
 * ================== Common Tasks ============================
 */

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

/**
 * ================== Common Tasks Ends ============================
 */

 /**
 * ================== Change Privacy Policy ============================
 */
export const CHANGE_PRIVACY_POLICY = 'CHANGE_PRIVACY_POLICY';

/**
 * ================== End Change Privacy Policy ============================
 */

 /**
 * ================== Request Available Centers LIst ============================
 */
export const REQUEST_AVAILABLE_CENTER = 'REQUEST_AVAILABLE_CENTER';
export const REQUEST_AVAILABLE_CENTER_SUCCESS = 'REQUEST_AVAILABLE_CENTER_SUCCESS';
export const REQUEST_AVAILABLE_CENTER_FAILED = 'REQUEST_AVAILABLE_CENTER_FAILED';

/**
 * ================== End Request Available Centers LIst ============================
 */


 /**
 * ================== Get Refresh Token Related Tasks ============================
 */

export const FETCH_REFRESH_TOKEN = 'FETCH_REFRESH_TOKEN';
export const FETCH_REFRESH_TOKEN_SUCCESS = 'FETCH_REFRESH_TOKEN_SUCCESS';
export const FETCH_REFRESH_TOKEN_FAILED = 'FETCH_REFRESH_TOKEN_FAILED';
export const RESET_REFRESH_TOKEN = 'RESET_REFRESH_TOKEN';

/**
 * ================== Get Refresh Token Related Tasks Ends ============================
 */

  /**
 * ================== Change Recapcha Related Tasks ============================
 */

export const CHANGE_RECAPCHA_STATUS = 'CHANGE_RECAPCHA_STATUS';

/**
 * ================== Change Recapcha Related Tasks Ends ============================
 */


/**
 * ================== Fetch Districts Related Tasks ============================
 */

 export const FETCH_DISTRICTS = 'FETCH_DISTRICTS';
 export const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS';
 export const FETCH_DISTRICTS_FAILED = 'FETCH_DISTRICTS_FAILED';
 export const RESET_DISTRICTS = 'RESET_DISTRICTS';
 
 /**
  * ================== Fetch Districts Related Tasks Ends ============================
  */

 /**
 * ================== Fetch MOH Related Tasks ============================
 */

export const FETCH_MOH = 'FETCH_MOH';
export const FETCH_MOH_SUCCESS = 'FETCH_MOH_SUCCESS';
export const FETCH_MOH_FAILED = 'FETCH_MOH_FAILED';
export const RESET_MOH = 'RESET_MOH';

/**
 * ================== Fetch MOH Related Tasks Ends ============================
 */

/**
 * ================== Fetch Center Locations Related Tasks ============================
 */

 export const FETCH_CENTER_LOCATIONS = 'FETCH_CENTER_LOCATIONS';
 export const FETCH_CENTER_LOCATIONS_SUCCESS = 'FETCH_CENTER_LOCATIONS_SUCCESS';
 export const FETCH_CENTER_LOCATIONS_FAILED = 'FETCH_CENTER_LOCATIONS_FAILED';
 export const RESET_CENTER_LOCATIONS = 'RESET_CENTER_LOCATIONS';
 
 /**
  * ================== Fetch Center Locations Related Tasks Ends ============================
  */

 /**
 * ================== Fetch Vaccine Type Tasks ============================
 */

  export const FETCH_VACCINE_TYPE = 'FETCH_VACCINE_TYPE';
  export const FETCH_VACCINE_TYPE_SUCCESS = 'FETCH_VACCINE_TYPE_SUCCESS';
  export const FETCH_VACCINE_TYPE_FAILED = 'FETCH_VACCINE_TYPE_FAILED';
  export const RESET_VACCINE_TYPE = 'RESET_VACCINE_TYPE';
  
  /**
   * ================== Fetch Vaccine Type Related Tasks Ends ============================
   */