import {
    FETCH_REGISTERED_USERS_SUCCESS,
    FETCH_REGISTERED_USERS_FAILED,
} from '../actions/types';

const initialState = {
    loading: true,
    error: undefined,
    isDownloadCertificate: false,
    isEnabledRegistrationFlow: true,
};

const registrationListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REGISTERED_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case FETCH_REGISTERED_USERS_FAILED:
            return {
                ...state,
                loading: false,
                error: 'fetchRegisteredUsersError',
            };
        default:
            return state;
    }
};

export default registrationListReducer;
