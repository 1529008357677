import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles , makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Redirect } from 'react-router-dom';

import { requestOTP, otpEntered, getAvailableCenters } from 'actions/signin';
import ErrorDisplay from 'views/Common/ErrorDisplay';
import Spinner from 'views/Common/Spinner';
import languageText from 'languages/signIn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';

import { changeRecapcha } from '../../actions/privacyPolicy';

import ReCAPTCHA from "react-google-recaptcha";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.common.white,
        padding: '20px',
        borderRadius: 15,
        boxShadow: '0 0 3px #ccc',
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    otpClass: {
        padding: '10px 50px',
    },
    tableContainer:{
        maxHeight: '350px',
        overflowY: 'auto',
        overflowX: 'auto',
    },
    vaccineList:{
        backgroundColor: '#e0e0e0',
        textAlign: 'center',
        fontSize: '18px',
        minHeight: '40px',
        padding: '12px',
        color: '#150101'
    },
    noContent:{
        padding: '12px',
    },
    recaptchaStyle:{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
      alertCalass:{
        padding: '12px',
        margin: '12px',
        lineHeight: '46px',
        fontSize: '18px',
        textAlign: 'center',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
      },
    warningMsg: {
        textAlign: "justify",
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        backgroundColor: '#fff7f7',
        lineHeight: '1.5rem',
    }
}));

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e6e6e6',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 15,
    },
}))(TableCell);

const mobileRegex = new RegExp('^[0][7][0-9]{8}$');

const SignIn = ({
    requestOTP,
    otpEntered,
    requestedOTP,
    loading,
    error,
    loginSuccess,
    otpRequestedTime,
    language,
    getAvailableCenters,
    centerList,
    isCheckedRecapcha,
    changeRecapcha,
}) => {
    const classes = useStyles();
    const reCaptchaRef = React.useRef();

    //const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";  // developments
    const TEST_SITE_KEY = "6LfmOK8bAAAAAKKR1YZTXzEfyaXfqsi2dIVFqIaf";  // STG  http://czportal.lgcc.gov.lk/
    //const TEST_SITE_KEY = "6LdsxeMaAAAAAD2JY7XKqoLcwbx5xUzzpFbQt9mz";   //prod  vaccine.covid19.gov.lk
    const DELAY = 500;

    const [mobile, setMobile] = useState('');
    const [otp, setOtp] = useState('');
    const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
    const [retryTimeLeft, setRetryTimeLeft] = useState(60);
    const [tempToken, setTempToken] = useState('');

    useEffect(() =>{
        getAvailableCenters();
    },[])

    const [load, setLoad] = useState(false);
    useEffect(() => {
        setTimeout(() => { setLoad(true); }, DELAY);
    },[])

    const handleChangeCapcha = tempValue => {
        if (tempValue === null){
            changeRecapcha(false);
        } else {
            setTempToken(tempValue);
            changeRecapcha(true);
        }
      };
    
      const [callback, setCallback] = useState(false);
      const asyncScriptOnLoad = () => {
        setCallback(true);
      };

    const onMobileNumberFieldChange = event => {
        let mobileValue = event.target.value;
        setMobile(mobileValue);
        setIsValidMobileNumber(
            mobileValue.length < 10 ? true : mobileRegex.test(mobileValue)
        );
    };

    const clickResendButton = () => {
        // wait for 1 min to allow retry
        if (new Date().valueOf() - otpRequestedTime < 60000) {
            setRetryTimeLeft(
                60 - (new Date().valueOf() - otpRequestedTime) / 1000
            );
        } else {
            setOtp('');
            requestOTP({'mobile':mobile,'tempToken':tempToken});
            setRetryTimeLeft(60);
        }
    };

    return loginSuccess ? (
        <Redirect to="/registration-list" />
    ) : loading ? (
        <Spinner></Spinner>
    ) : error ? (
        <React.Fragment>
            <ErrorDisplay error={error} refreshButton={true}></ErrorDisplay>
        </React.Fragment>
    ) : (
        <div className={classes.root}>
            <Container
                component="main"
                maxWidth="xs"
                className={classes.container}
                style = {{'max-width':'500px'}}
            >
                <CssBaseline />
                <div className={classes.paper}>
                      <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar> 
                   <Typography component="h1" variant="h4">
                        {requestedOTP
                            ? languageText.otpEnter[language]
                            : languageText.signIn[language]}
                    </Typography>  

                    {!requestedOTP ? (
                        <div>
                            
                          <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="mobile"
                                label={
                                    languageText.mobileNumberExample[language]
                                }
                                name="mobile"
                                autoFocus
                                disabled={requestedOTP}
                                helperText={
                                    languageText.mobileNumberHelper[language]
                                }
                                onChange={onMobileNumberFieldChange}
                                error={!isValidMobileNumber}
                                style={{'margin-left': '23px', 'width':'90%'}}
                            />
                            <div className={classes.recaptchaStyle}>
                                {load && (
                                    <ReCAPTCHA
                                        style={{ display: "inline-block",  }}
                                        ref={reCaptchaRef}
                                        sitekey={TEST_SITE_KEY}
                                        onChange={handleChangeCapcha}
                                        asyncScriptOnLoad={asyncScriptOnLoad}
                                    />
                                )}
                            </div>
                                 <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                className={classes.submit}
                                onClick={() => requestOTP({'mobile':mobile,'tempToken':tempToken})}
                                disabled={
                                    mobile.length !== 10 || !isValidMobileNumber || !isCheckedRecapcha
                                }
                                type="submit"
                            >
                                {languageText.sendOtpCode[language]}
                            </Button>
                        </form> 
                        {/* <br></br>
                        <div className={classes.root}>
                            <Alert severity="warning" className={classes.alertCalass}><h3>{languageText.notAvailableWaring[language]}</h3></Alert>
                        </div>
                        <br></br>  */}
                        <br/>
                        <br/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Alert severity="warning" className={classes.warningMsg}>{languageText.warningMsg[language]}</Alert>
                            </Grid>
                        </Grid>
                        <br/>
                        <br/>
                          <div className={classes.vaccineList}> {languageText.centerListDisplayName[language]}</div> 
                              {
                                    centerList.length === 0 ? 
                                        <Typography component="h6" variant="h6" align="center" className={classes.noContent}>
                                            {languageText.noContent[language]}  
                                        </Typography>
                                        :
                                        <div className={classes.tableContainer}>
                                            <TableContainer>
                                                <Table
                                                    className={classes.table}
                                                    aria-label="customized table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>
                                                                {languageText.date[language]}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {languageText.centerName[language]}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {languageText.vaccineType[language]}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {languageText.ageGroup[language]}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                        <TableBody>
                                                        {
                                                        centerList.map((center,index) => (
                                                            <StyledTableRow key = {index}>
                                                                <StyledTableCell>
                                                                        {moment(new Date(center.date)).format('Do MMMM YYYY')}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                        {center.mohCenter + ' | ' + center.centerName + (center.dose === "DOSE1" ? '' : (' | ' + center.vaccineType)) }
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                        {center.dose}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                        {center.ageGroup}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                                
                                                        ))
                                                        }
                                                            </TableBody>
                                                </Table>
                                            </TableContainer>
                                    </div> 
                        } 
                         
                        </div>
                    ) : (
                        <React.Fragment>
                            <form className={classes.form} noValidate>
                                <Typography variant="subtitle2" align="center">
                                {`${languageText.otpSentMessagePartOne[language]}${mobile.substr(mobile.length - 4)}${languageText.otpSentMessagePartTwo[language]}`}
                                    {/* {`${languageText.otpSentMessage[language]} ${mobile}`} */}
                                </Typography>
                                <div className={classes.otpClass}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="mobile"
                                        onChange={e => setOtp(e.target.value)}
                                        autoFocus
                                        disabled={false}
                                        onInput={e => {
                                            if(!isNaN(e.target.value)){
                                                 e.target.value = Math.max(
                                                    0,
                                                    parseInt(e.target.value)
                                                )
                                                    .toString()
                                                    .slice(0, 6); 
                                            } else{
                                                e.target.value = "";
                                            }
                                        }}
                                        label={languageText.otpEnter[language]}
                                    />
                                </div>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() =>
                                        otpEntered({
                                            mobileno: mobile,
                                            otp: parseInt(otp),
                                        })
                                    }
                                    disabled={otp.length !== 6}
                                    type="submit"
                                >
                                    {languageText.signIn[language]}
                                </Button>
                                <br />
                                <Typography variant="subtitle2" align="center">
                                    {languageText.didNotReceiveCode[language]}
                                </Typography>
                            </form>
                            <Link
                                onClick={clickResendButton}
                                variant="body2"
                                component="button"
                                color="primary"
                                align="center"
                            >
                                {`${languageText.sendCodeAgain[language]} ${
                                    retryTimeLeft < 60
                                        ? `(${languageText.retryIn[language]} ` +
                                          Math.floor(retryTimeLeft) +
                                          ` ${languageText.seconds[language]})`
                                        : ''
                                }`}
                            </Link>
                        </React.Fragment>
                    )}
                </div>
            </Container>
        </div>
    );
};

function mapStateToProps({ signin, common, privacyPolicy }) {
    let {
        requestedOTP,
        loading,
        error,
        loginSuccess,
        otpRequestedTime,
        centerList,
    } = signin;
    let { language } = common;
    let { isCheckedRecapcha } = privacyPolicy
    return {
        requestedOTP,
        loading,
        error,
        loginSuccess,
        otpRequestedTime,
        centerList,
        isCheckedRecapcha,
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, { requestOTP, otpEntered, getAvailableCenters , changeRecapcha})(SignIn);
