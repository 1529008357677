import {
    FETCH_DISTRICTS,
    FETCH_DISTRICTS_SUCCESS,
    FETCH_DISTRICTS_FAILED,
    RESET_DISTRICTS,
    FETCH_MOH,
    FETCH_MOH_SUCCESS,
    FETCH_MOH_FAILED,
    RESET_MOH,
    FETCH_CENTER_LOCATIONS,
    FETCH_CENTER_LOCATIONS_SUCCESS,
    FETCH_CENTER_LOCATIONS_FAILED,
    RESET_CENTER_LOCATIONS,
} from './types';

/** FETCH DISTRICTS */
export const fetchDistricts = payload => {
    return { type: FETCH_DISTRICTS, payload };
};

export const fetchDistrictsSuccess = payload => {
    return { type: FETCH_DISTRICTS_SUCCESS, payload };
};

export const fetchDistrictsFailed = payload => {
    return { type: FETCH_DISTRICTS_FAILED, payload };
};

export const resetfetchDistricts = payload => {
    return { type: RESET_DISTRICTS, payload };
};

/** FETCH MOH */
export const fetchMohs = payload => {
    return { type: FETCH_MOH, payload };
};

export const fetchMohsSuccess = payload => {
    return { type: FETCH_MOH_SUCCESS, payload };
};

export const fetchMohsFailed = payload => {
    return { type: FETCH_MOH_FAILED, payload };
};

export const resetfetchMohs = payload => {
    return { type: RESET_MOH, payload };
};

/** FETCH LOCATIONS */
export const fetchVaccineLocations = payload => {
    return { type: FETCH_CENTER_LOCATIONS, payload };
};

export const fetchVaccineLocationsSuccess = payload => {
    return { type: FETCH_CENTER_LOCATIONS_SUCCESS, payload };
};

export const fetchVaccineLocationsFailed = payload => {
    return { type: FETCH_CENTER_LOCATIONS_FAILED, payload };
};

export const resetfetchVaccineLocations = payload => {
    return { type: RESET_CENTER_LOCATIONS, payload };
};
