const centerSelection = {
    nextCenterDetails: {
        english: 'Vaccination Center Details',
        sinhala: 'එන්නත් මධ්‍යස්ථානයේ විස්තර',
        tamil: 'தடுப்பூசி மையத்தின் விவரங்கள்',
    },
    selectDistrict: {
        english: 'Select Your District',
        sinhala: 'ඔබේ දිස්ත්‍රික්කය තෝරන්න',
        tamil: 'உங்கள் மாவட்டத்தைத் தேர்ந்தெடுக்கவும்',
    },
    selectPreferredCenter: {
        english: 'Select Your MOH Area',
        sinhala: 'ඔබේ MOH ප්‍රදේශය තෝරන්න',
        tamil: 'உங்கள் MOH பகுதியைத் தேர்ந்தெடுக்கவும்',
    },
    selectPreferredLocation: {
        english: 'Select Preferred Vaccination Center',
        sinhala: 'කැමති එන්නත් කිරීමේ මධ්‍යස්ථානය තෝරන්න',
        tamil: 'விருப்பமான தடுப்பூசி இருப்பிடத்தைத் தேர்ந்தெடுக்கவும்',
    },
};

export default centerSelection;
