import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { Redirect, useHistory } from 'react-router-dom';

import { fetchRegisteredUsers } from 'actions/registrationList';
import ErrorDisplay from 'views/Common/ErrorDisplay';
import Spinner from 'views/Common/Spinner';
import languageText from 'languages/registrationList';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: '60%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    buttonWraper: {
        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            display: "grid"
        },
       // display: "grid"
    },
    button :{
        marginLeft: '12px',
        marginRight: '12px',
        marginTop: '12px',
        marginBottom: '12px',
    }
}));

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e6e6e6',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 15,
    },
}))(TableCell);

const Registration = ({
    loading,
    error,
    fetchRegisteredUsers,
    mobile,
    loginSuccess,
    loginSuccessTime,
    data,
    language,
    isDownloadCertificate,
    isEnabledRegistrationFlow
}) => {
    const classes = useStyles();
    const history = useHistory();
    
    useEffect(() => {
        fetchRegisteredUsers(mobile);
    }, [fetchRegisteredUsers, mobile]);

    /* if (new Date().valueOf() - loginSuccessTime > 7200000) {
        loginSuccess = false;
        sessionStorage.clear();
    }  */

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const viewCertificate =(nic , user) =>{
        history.push("/view-certificate", { "user": user})
    }

    return !loginSuccess ? (
        <Redirect to="/sign-in" />
    ) : loading ? (
        <Spinner></Spinner>
    ) : error ? (
        <ErrorDisplay error={error}></ErrorDisplay>
    ) : (
        <div className={classes.root}>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h2" align="center">
                        {languageText.previousRegistrations[language]}
                    </Typography>
                    <br />
                    <br />
                    {data.length === 0 ? (
                        <Typography component="h6" variant="h6" align="center">
                            {languageText.noRegistrations[language]}
                           {/*  {languageText.appointmentDisabled[language]} */}
                        </Typography>
                    ) : (
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                {languageText.name[language]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {languageText.nic[language]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {
                                                    languageText
                                                        .vaccinationCenter[
                                                        language
                                                    ]
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {languageText.date[language]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {
                                                    languageText.timeSlot[
                                                        language
                                                    ]
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {
                                                    languageText.dose[language]
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {/* {languageText.action[language]} */}
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map(user => (
                                                <StyledTableRow key={user.nic}>
                                                    <StyledTableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {user.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {user.nic}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {user.vcentername}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                            {moment(new Date(user.vaccinationDate)).format('DD-MM-YYYY')}
                                                      {/*   {user.vaccinationDate} */}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {user.timeslot}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {user.recivedFisrtDose === "N" ? "Second Dose" : "Booster Dose"}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {/* <Button
                                                            variant="contained"
                                                            className={
                                                                classes.button
                                                            }
                                                            disabled={true}
                                                        >
                                                            {
                                                                languageText
                                                                    .remove[
                                                                    language
                                                                ]
                                                            }
                                                        </Button> */}
                                                        {/* <Button
                                                            id={user.nic}
                                                            variant="contained"
                                                            className={classes.button}
                                                            disabled={user.viewCertificate === "false" ? true : false}
                                                            // href="/view-certificate"
                                                            onClick={() => { viewCertificate(user.nic, user) }}
                                                        >
                                                            {
                                                                languageText.viewCertificate[
                                                                language
                                                                ]
                                                            }
                                                        </Button> */}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 50]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </React.Fragment>
                    )}
                    <br />
                    <br />
                    <div className={classes.buttonWraper}>
                       {/*  <Tooltip title={languageText.registarionFlowMsg[language]} arrow> */}
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isEnabledRegistrationFlow}
                                    className={classes.button}
                                    href="/registration"
                                >
                                    {languageText.makeNewRegistration[language]}
                                </Button>
                            </span>
                       {/*  </Tooltip> */}
                       {/*  <Tooltip title={languageText.downloadCertificateToolTip[language]} arrow>
                            <span> */}
                                {/* <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    href="/request-certificate"
                                    disabled={!isDownloadCertificate}
                                >
                                    {languageText.downLoadCertificate[language]}
                                </Button> */}
                            {/* </span>
                        </Tooltip> */}
                    </div>
                </Paper>
            </main>
        </div>
    );
};

function mapStateToProps({ registrationList, common }) {
    let { loading, error, data, isDownloadCertificate, isEnabledRegistrationFlow } = registrationList;
    let loginSuccess = sessionStorage.getItem('loginSuccess');
    let loginSuccessTime = sessionStorage.getItem('loginSuccessTime');
    let mobile = sessionStorage.getItem('mobile');
    let { language } = common;
    return {
        loading,
        error,
        mobile,
        loginSuccess,
        loginSuccessTime,
        data,
        isDownloadCertificate,
        isEnabledRegistrationFlow,
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, { fetchRegisteredUsers })(Registration);
