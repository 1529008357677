import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Redirect, useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { submitFormRegistration } from 'actions/registration';
import ErrorDisplay from 'views/Common/ErrorDisplay';
import Spinner from 'views/Common/Spinner';
import languageText from 'languages/registration';
import RequestCertificateForm from './components/RequestCertificateForm';

const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const RequestCertificate = ({
    loading,
    error,
    language,
    loginSuccess,
    loginSuccessTime,
    mobile
}) => {
    const classes = useStyles();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [validationMessage, setValidationMessage] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const oldNICRegex = new RegExp('^[1-9][0-9]{8}[X,V,x,v]$');
    const newNICRegex = new RegExp('^[18,19,20,21]{2}[0-9]{10}$');

    const handleNextBasicDetails = () => {
        let tempNic = sessionStorage.getItem('nic');
        if (
            (!sessionStorage.getItem('nic') ||
                (!oldNICRegex.test(tempNic) && !newNICRegex.test(tempNic)))
        ) {
            setValidationMessage(languageText.enterValidNIC[language]);
            handleClickOpen();
        }  else {
            let user = {
                mobile: sessionStorage.getItem("mobile"),
                nic: tempNic,
                name: "",
            }
            history.push("/view-certificate", { "user": user})
            sessionStorage.getItem('nic');
        }
        sessionStorage.removeItem('nic');
    };

    const handleBack = () => {
        history.push("/registration-list");
        sessionStorage.removeItem('nic');
    }

    /* if (new Date().valueOf() - loginSuccessTime > 7200000) {
        loginSuccess = false;
        sessionStorage.clear();
    } */

    return !loginSuccess ? (
        <Redirect to="/sign-in" />
    ) : loading ? (
        <Spinner></Spinner>
    ) : error ? (
        <ErrorDisplay error={error}></ErrorDisplay>
    ) : (
            <div className={classes.root}>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h3" align="center">
                            {/* {languageText.userRegistrations[language]} */}
                            {languageText.certificate[language]}
                        </Typography>
                        <React.Fragment>
                            <React.Fragment>
                                <RequestCertificateForm />
                                <div className={classes.buttons}>
                                    <Button
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                       {languageText.back[language]}
                                    </Button>
                                    {
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNextBasicDetails}
                                            className={classes.button}
                                        >
                                            {
                                                languageText.viewCertificate[language]
                                            }
                                        </Button>
                                    }
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    </Paper>
                </main>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {languageText.validationError[language]}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {validationMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            {languageText.close[language]}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
};

function mapStateToProps({ signin, registration, common, privacyPolicy }) {
    let { mobile } = signin;
    let { confirmation } = registration;
    let loginSuccess = sessionStorage.getItem('loginSuccess');
    let loginSuccessTime = sessionStorage.getItem('loginSuccessTime');
    let { language } = common;
    let { isAgreePrivacy } = privacyPolicy
    return {
        mobile,
        confirmation,
        loginSuccess,
        loginSuccessTime,
        isAgreePrivacy,
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, { submitFormRegistration })(
    RequestCertificate
);
