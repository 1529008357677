import React from 'react';
import { connect } from 'react-redux';
//import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import languageText from 'languages/basicRegistration';

/* const useStyles = makeStyles(theme => ({
    formControl: {
        width: '200px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
})); */

const oldNICRegex = new RegExp('^[1-9][0-9]{8}[X,V,x,v]$');
const newNICRegex = new RegExp('^[18,19,20,21]{2}[0-9]{10}$');

const RequestCertificateForm = ({ language }) => {
    //const classes = useStyles();
    const [nic, setNic] = React.useState(
        sessionStorage.getItem('nic') ? sessionStorage.getItem('nic') : ''
    );
    const [isValidNic, setIsValidNic] = React.useState(true);


    const handleNicChange = event => {
        let tempNic = event.target.value;
        setNic(tempNic);
        setIsValidNic(oldNICRegex.test(tempNic) || newNICRegex.test(tempNic));
        sessionStorage.setItem('nic', tempNic);
    }

    let dateBefore18Years = new Date();
    dateBefore18Years.setFullYear(dateBefore18Years.getFullYear() - 18);

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                {/* {languageText.userDetails[language]} */}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        required
                        value={nic}
                        id="nic"
                        name="nic"
                        label={languageText.nic[language]}
                        fullWidth
                        autoComplete="nic"
                        helperText={languageText.nicHelperText[language]}
                        onChange={handleNicChange}
                        error={!isValidNic}
                    />
                </Grid>
            </Grid>

            <br />
            <br />
            
        </div>
    );
};

function mapStateToProps({ signin, common }) {
    let { requestedOTP, loading, error } = signin;
    let { language } = common;
    return {
        requestedOTP,
        loading,
        error,
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, {})(RequestCertificateForm);
