import signInSaga from './signIn';
import registrationListSaga from './registrationList';
import registrationSaga from './registration';
import certificateViewSaga from './certificateView';
import tokenSaga from './token';
import centerSaga from './vaccineCenters';

/**
 * Add new sagas here
 */
const sagas = [signInSaga, registrationListSaga, registrationSaga, certificateViewSaga, tokenSaga, centerSaga];

/**
 * Combine sagas for redux middleware
 */
export default sagaMiddleware => {
    sagas.forEach(saga => {
        sagaMiddleware.run(saga);
    });
};
