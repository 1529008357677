import { call, all, put, takeLatest } from 'redux-saga/effects';

import vaccineLocationApi from '../services/vaccineCenters';

import { 
    FETCH_DISTRICTS,
    FETCH_MOH,
    FETCH_CENTER_LOCATIONS,
 } from '../actions/types';

import {
    fetchDistrictsSuccess,
    fetchDistrictsFailed,
    fetchMohsSuccess,
    fetchMohsFailed,
    fetchVaccineLocationsSuccess,
    fetchVaccineLocationsFailed,
} from '../actions/vaccineCenter';


/**
 * Centers in saga implementation
 */
function* fetchDistrictsSaga({ payload }) {
    try {
        let data = yield call(vaccineLocationApi.location.getDistricts, payload);
        if(data.status){
            yield put(fetchDistrictsSuccess(data.data));
        }else{
            yield put(fetchDistrictsFailed(data.data));
        }
    } catch (err) {
        let error = {}
        yield put(fetchDistrictsFailed(error));
    } 
}

function* fetchMohCentersSaga({ payload }) {
    try {
        let data = yield call(vaccineLocationApi.location.getMohs, payload);
        if(data.status){
            yield put(fetchMohsSuccess(data.data));
        }else{
            yield put(fetchMohsFailed(data.data));
        }
    } catch (err) {
        let error = {}
        yield put(fetchMohsFailed(error));
    } 
}

function* fetchVaccineLocationsSaga({ payload }) {
    try {
        const data = yield call(vaccineLocationApi.location.getCenters, payload);
        if(data.status){
            yield put(fetchVaccineLocationsSuccess(data.data));
        }else{
            yield put(fetchVaccineLocationsFailed(data.data));
        }
    } catch (err) {
        let error = {}
        yield put(fetchVaccineLocationsFailed(error));
    } 
}

function* watchVaccineCentersActions() {
    yield takeLatest(FETCH_DISTRICTS, fetchDistrictsSaga);
    yield takeLatest(FETCH_MOH, fetchMohCentersSaga);
    yield takeLatest(FETCH_CENTER_LOCATIONS, fetchVaccineLocationsSaga);
}

export default function* centerSaga() {
    yield all([watchVaccineCentersActions()]);
}