import {
    FETCH_CERTIFICATE_SUCCESS,
    FETCH_CERTIFICATE_FAILED,
    FETCH_CERTIFICATE
} from '../actions/types';

const initialState = {
    loading: true,
    error: undefined,
};

const certificateReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CERTIFICATE:
            return {
                ...state,
                loading: true,
            };

       case FETCH_CERTIFICATE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
            
        case FETCH_CERTIFICATE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.errorDescription,//'fetchCerificateError',
                data: null,
            };
        default:
            return state;
    }
};

export default certificateReducer;
