import { call, all, put, takeLatest } from 'redux-saga/effects';

import certificateApi from '../services/certificateApi';

import {FETCH_CERTIFICATE} from '../actions/types';

import {
    fetchCertificateSuccess,
    fetchCertificateFailed
} from '../actions/certificateView';

/**
 * sign in saga implementation
 */

function* fetchCertificate({ payload }) {
    try {
        const data = yield call(certificateApi.certificate.submit, payload);

        if(data.data.status === "unsuccess"){
            yield put(fetchCertificateFailed(data.data));
        }else{
            var binary_string = window.atob(data.data.certData);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
        
            let data1 = {
                "data" : {
                    "certData" :  bytes.buffer
                }
            }
    
            yield put(fetchCertificateSuccess(data1));
        }

    } catch (err) {
        yield put(fetchCertificateFailed(err));
    }
}

function* watchCertificateViewActions() {
    yield takeLatest(FETCH_CERTIFICATE, fetchCertificate);
}



export default function* certificateViewSaga() {
    yield all([watchCertificateViewActions()]);
}