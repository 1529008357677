import axios from 'axios';

export default {
    users: {
        getRegisteredUsers: payload =>
            axios
                .get(`/getRegisterCitizen`)
                .then(res => res.data),
    },
    centers: {
        getCenters: () =>
            axios.get(`/getCentersList?sessionOnly=true`).then(res => res.data),
    },
    registration: {
        submit: payload =>
            axios
                .post(`/citizenRegistration`, { ...payload })
                .then(res => res.data),
    },
    getVaccineType: {
        vaccineType: payload =>
            axios
                .get(`/telco/getVaccineTypes`)
                .then(res => res.data),
    },
};
