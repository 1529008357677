import {
    FETCH_DISTRICTS,
    FETCH_DISTRICTS_SUCCESS,
    FETCH_DISTRICTS_FAILED,
    RESET_DISTRICTS,
    FETCH_MOH,
    FETCH_MOH_SUCCESS,
    FETCH_MOH_FAILED,
    RESET_MOH,
    FETCH_CENTER_LOCATIONS,
    FETCH_CENTER_LOCATIONS_SUCCESS,
    FETCH_CENTER_LOCATIONS_FAILED,
    RESET_CENTER_LOCATIONS,
} from '../actions/types';

const initialState = {
    loading: false,
    error: undefined,
    districts:{
        districts: []
    },
    mohs: {
        mohs: []
    },
    location: {
        locations: []
    } 
};

const vaccineLocationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DISTRICTS:
            return {
                ...state,
                loading: true,
                mohs: {
                    mohs: []
                },
                location: {
                    locations: []
                }
            };
        case FETCH_DISTRICTS_SUCCESS:
            return {
                ...state,
                loading: false,
                districts : {
                    districts: action.payload.data
                }
            };
        case FETCH_DISTRICTS_FAILED:
            return {
                ...state,
                loading: false,
                error:'Fetch Districts Faild',
                districts : {
                    districts: []
                },
            };
        case RESET_DISTRICTS:
            return {
                ...state,
                loading: false,
                error: undefined,
                districts : {
                    districts: []
                },
            };

        /** Fetch MOHs */
        case FETCH_MOH:
            return {
                ...state,
                loading: true,
                location: {
                    locations: []
                }
            };
        case FETCH_MOH_SUCCESS:
            return {
                ...state,
                loading: false,
                mohs: {
                    mohs: action.payload.data
                }
            };
        case FETCH_MOH_FAILED:
            return {
                ...state,
                loading: false,
                error:'Fetch MOHs Faild',
                mohs: {
                    mohs: []
                },
            };
        case RESET_MOH:
            return {
                ...state,
                loading: false,
                error: undefined,
                mohs: {
                    mohs: []
                },
            };

        /** Fetch vaccine locations */
        case FETCH_CENTER_LOCATIONS:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CENTER_LOCATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                location: {
                    locations: action.payload.data
                }
            };
        case FETCH_CENTER_LOCATIONS_FAILED:
            return {
                ...state,
                loading: false,
                error:'Fetch Vaccine Centers Faild',
                location: {
                    locations: []
                },
            };
        case RESET_CENTER_LOCATIONS:
            return {
                ...state,
                loading: false,
                error: undefined,
                location: {
                    locations: []
                },
            };

        default:
            return state;
    }
};

export default vaccineLocationsReducer;
