import { CHANGE_LANGUAGE } from '../actions/types';

const initialState = {
    language: 'english',
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            sessionStorage.setItem('language', action.payload);
            return {
                language: action.payload,
            };
        default:
            return state;
    }
};

export default commonReducer;
