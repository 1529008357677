import React from 'react';
import { Typography, Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import languageText from 'languages/signIn';
import errorText from 'languages/errors';

const errorDisplay = {
    height: '200px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const refreshButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
};
const errorTextStyle = {
    paddingRight: '5px',
    textAlign: 'left'
};

const ErrorDisplay = ({ error, refreshButton, language, backButton, errorDescription}) => {
    const histrory = useHistory();
console.log(language + " ddddddddddddd " + error)
    const backToUserList = () =>{
        histrory.push("/registration-list");
    }
    return (
        <div>
            <div style={errorDisplay} className="error-display">
                <ErrorIcon color="error"></ErrorIcon>
                <Typography
                    variant="h5"
                    className="error-text-style"
                    style={errorTextStyle}
                >
                    {
                        errorDescription && errorDescription.includes("nicAlreadyAttached-") ? 
                            (errorText[`${errorDescription.split("-")[0]}Part1`][language] + 
                                `${errorDescription.split("-")[1]}` + errorText[`${errorDescription.split("-")[0]}Part2`][language]) : 
                                    (
                                        errorDescription &&  errorDescription !== "" ? 
                                            errorText[errorDescription][language] :
                                            
                                            (
                                                error && error.includes("nicAlreadyAttached-") ? 
                                                (errorText[`${error.split("-")[0]}CertPart1`][language] + 
                                                    `${error.split("-")[1]}` + errorText[`${error.split("-")[0]}CertPart2`][language])
                                                : errorText[error][language]
                                            )
                                            
                                    )
                        
                    }
                    
                </Typography>
            </div>
            <div style={refreshButtonStyle}>
                {refreshButton && (
                    <div>
                        <Typography
                            variant="h5"
                            className="error-text"
                            style={errorText}
                        >
                            {languageText.please[language]}{' '}
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => window.location.reload()}
                            >
                                {languageText.clickHere[language]}
                            </Button>{' '}
                            {languageText.startOver[language]}
                        </Typography>
                    </div>
                )}
                {
                    backButton && (
                        <div>
                            <Typography
                                variant="h5"
                                className="error-text"
                                style={errorText}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => backToUserList()}
                                >
                                    {languageText.backButton[language]}
                                </Button>{' '}
                            </Typography>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

function mapStateToProps({ common }) {
    let { language } = common;
    return {
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, {})(ErrorDisplay);
