import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import Root from 'Root';

import ReactGA from 'react-ga';

ReactGA.initialize('G-HWCMNCWVEQ');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <Root>
        <App />
    </Root>,
    document.getElementById('root')
);

serviceWorker.unregister();
