import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { fetchDistricts, fetchMohs, fetchVaccineLocations } from 'actions/vaccineCenter';
import ErrorDisplay from 'views/Common/ErrorDisplay';
import Spinner from 'views/Common/Spinner';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import languageText from 'languages/centerSelection';

const Registration = ({ language, fetchDistricts, loading, error, districts, fetchMohs, mohs, locations, fetchVaccineLocations }) => {
    const defaultProps = {
        options: districts,
        getOptionLabel: option => option?.districtName,
    };

    const setDefaultMohList = {
        options: mohs,
        getOptionLabel: option => option?.mohName,
    };

    const defaultLocationList = {
        options: locations,
        getOptionLabel: option => option?.locationName,
    };

    const [isDisabledLocation,setIsDisabledLocation] = useState(true);
    const [isDisabledMoh,setIsDisabledMoh] = useState(true);

    const [district, setDistrict] = React.useState(
        JSON.parse(sessionStorage.getItem('district'))
    );

    const [moh, setChangeMoh] = React.useState(
        JSON.parse(sessionStorage.getItem('moh'))
    );

    const [location, setLocation] = React.useState(
        JSON.parse(sessionStorage.getItem('location'))
    );

    const handleDistrictChange = (event, newValue) => {
        setIsDisabledMoh(true);
        setIsDisabledLocation(true);
        if (newValue === null) {
            resetDistrict();
        } else {
            let districtId = newValue.id;
            let districtName = newValue.districtName;
            setDistrict(newValue);
            sessionStorage.setItem('district', JSON.stringify(newValue));
            sessionStorage.setItem('districtName',districtName);
            sessionStorage.setItem('districtId',districtId);
            fetchMohs(districtId);
            setIsDisabledMoh(false);
        }
        setChangeMoh(null);
        setLocation(null);
        resetMoh();
        resetVaccineCenter();
    };

    const handleMohCenterChange = (event, newValue) => {
        if (newValue === null) {
            resetMoh();
       } else {
           let mohId = newValue.id;
           let mohName = newValue.mohName;
           setChangeMoh(newValue);
           sessionStorage.setItem('moh', JSON.stringify(newValue));
           sessionStorage.setItem('mohName',mohName);
           sessionStorage.setItem('mohId',mohId);
           //fetchVaccineLocations(mohId);
           setIsDisabledLocation(false);
           fetchVaccineLocations({
               mohId: mohId,
               dose: sessionStorage.getItem("firstVaccine") === 'no' ? 'DOSE2' : 'DOSE3',
               vaccineTypeId: sessionStorage.getItem('firstVaccine') === 'yes' ? 0/* Number.parseInt(sessionStorage.getItem('vaccineTypeId')) */ : 0,
           });
       }
       setLocation(null);
       resetVaccineCenter();
   };

    const handleLocationChange = (event, newValue) => {
         if (newValue === null) {
            resetVaccineCenter();
        } else {
            let locationId = newValue.locationId;
            let locationName = newValue.locationName;
            setLocation(newValue);
            sessionStorage.setItem('locationId', locationId);
            sessionStorage.setItem('locationName',locationName);
            sessionStorage.setItem('location',JSON.stringify(newValue));
        } 
    }

    const resetDistrict = () => {
        sessionStorage.removeItem('districtId');
        sessionStorage.removeItem('districtName');
        sessionStorage.removeItem('district');
    }

    const resetMoh = () => {
        sessionStorage.removeItem('moh');
        sessionStorage.removeItem('mohName');
        sessionStorage.removeItem('mohId');
    }

    const resetVaccineCenter = () => {
        sessionStorage.removeItem('locationId');
        sessionStorage.removeItem('locationName');
        sessionStorage.removeItem('location');
    }

    useEffect(() => {
       // fetchCenters();
        fetchDistricts();
    }, [fetchDistricts]);

    return loading ? (
        <Spinner></Spinner>
    ) : error ? (
        <ErrorDisplay error={error}></ErrorDisplay>
    ) : (
        <div>
            <Typography variant="h6" gutterBottom>
                {languageText.nextCenterDetails[language]}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Autocomplete
                        {...defaultProps}
                        id="auto-select"
                        autoComplete
                        includeInputInList
                        onChange={(event, newValue) => {
                            handleDistrictChange(event, newValue);
                        }}
                        value={district}
                        disableClearable
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={
                                    languageText.selectDistrict[language]
                                }
                                margin="normal"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Autocomplete
                        {...setDefaultMohList}
                        id="auto-select-moh"
                        autoComplete
                        disabled={isDisabledMoh}
                        includeInputInList
                        onChange={(event, newValue) => {
                            handleMohCenterChange(event, newValue);
                        }}
                        value={moh}
                        disableClearable
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={
                                    languageText.selectPreferredCenter[language]
                                }
                                margin="normal"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    {locations.length === 0 && !isDisabledMoh && moh !== null? <p style={{color : 'red'}}>No active sessions available</p> : ''}
                    <Autocomplete
                        {...defaultLocationList}
                        id="auto-select-location"
                        autoComplete
                        disabled={isDisabledLocation || locations.length === 0}
                        includeInputInList
                        getOptionLabel={option => option?.locationName}
                        onChange={(event, newValue) => {
                            handleLocationChange(event, newValue);
                        }}
                        value={location}
                        disableClearable
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={
                                    languageText.selectPreferredLocation[language]
                                }
                                margin="normal"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

function mapStateToProps({ common, vaccineLocation }) {
   // let { centers } = registration;
    let { language } = common;
    let { loading, error } = vaccineLocation
    let districts = vaccineLocation.districts.districts
    let mohs = vaccineLocation.mohs.mohs
    let locations = vaccineLocation.location.locations
    error = undefined
    return {
      //  centers,
        language: sessionStorage.getItem('language') || language,
        loading,
        error,
        districts,
        mohs,
        locations,
    };
}

export default connect(mapStateToProps, { fetchDistricts, fetchMohs, fetchVaccineLocations })(Registration);
