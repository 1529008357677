import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import languageText from 'languages/confirmation';
import moment from 'moment';
import { Checkbox } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';

import { changePrivacyPolicy } from 'actions/privacyPolicy';


const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    privacyPolicy : {
        display: "flex",
        justifyContent: "center",
        width: 100,
        fontSize: 16,
        color: "black",
    },
    privacyLink :{
        fontSize: 16,
        color: "black",
    },
    warningMsg: {
        textAlign: "justify",
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        backgroundColor: '#fff7f7',
        lineHeight: '1.5rem',
    }
}));

const Registration = ({ language, changePrivacyPolicy,isAgreePrivacy }) => {
    const classes = useStyles();
    
    const handleAgreePrivacyPolicy = (e) => {
        changePrivacyPolicy(e.target.checked);
    }

    const handleChangePrivacyPolicy = () => {
        //history.push("/privacy");
        const win = window.open("/privacy", "_blank");
        win.focus();
    }

    return (
        <div className={classes.root}>
             <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Alert severity="warning" className={classes.warningMsg}>{languageText.warningMsg[language]}</Alert>
                </Grid>
            </Grid>
            <br></br>
            <Typography variant="h6" gutterBottom>
                {languageText.registrationSummary[language]}
            </Typography>
            <List disablePadding>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={languageText.name[language]} />
                    <Typography variant="body2">
                        {sessionStorage.getItem('fullName').charAt(0).toUpperCase() + sessionStorage.getItem('fullName').slice(1)}
                    </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={languageText.nic[language]} />
                    <Typography variant="body2">
                        {sessionStorage.getItem('nic')}
                    </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={languageText.gender[language]} />
                    <Typography variant="body2">
                        {sessionStorage
                            .getItem('gender')
                            .charAt(0)
                            .toUpperCase() +
                            sessionStorage.getItem('gender').slice(1)}
                    </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={languageText.dob[language]} />
                    <Typography variant="body2">
                        {
                        moment(sessionStorage.getItem('dob')).format('DD-MM-YYYY')
                        /*  dateFormat(
                            sessionStorage.getItem('dob'),
                            'dd-MMMM-yyyy'
                        ) */ }
                    </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemText
                        primary={languageText.firstVaccine[language]}
                    />
                    <Typography variant="subtitle1" className={classes.total}>
                        {sessionStorage.getItem('firstVaccine')
                            ? sessionStorage
                                  .getItem('firstVaccine')
                                  .charAt(0)
                                  .toUpperCase() +
                              sessionStorage.getItem('firstVaccine').slice(1)
                            : 'No'}
                    </Typography>
                </ListItem>
               {/*  {
                    sessionStorage.getItem('firstVaccine') === 'no' ? null :
                        <React.Fragment>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary={languageText.vaccineFirstDoseDate[language]} />
                                <Typography variant="subtitle1">
                                    {moment(sessionStorage.getItem('firstVaccineDate')).format('DD-MM-YYYY')}
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                            <ListItemText primary={languageText.vaccineType[language]} />
                                <Typography variant="subtitle1">
                                    {sessionStorage.getItem('vaccineTypeName')}
                                </Typography>
                            </ListItem>
                        </React.Fragment>
                } */}
                
            </List>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.title}
                    >
                        {languageText.centerDetails[language]}
                    </Typography>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            primary={languageText.districtName[language]}
                        />
                        <Typography gutterBottom>
                            {sessionStorage.getItem('districtName')}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            primary={languageText.mohCenterName[language]}
                        />
                        <Typography gutterBottom>
                            {sessionStorage.getItem('mohName')}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            primary={languageText.locationName[language]}
                        />
                        <Typography gutterBottom>
                            {sessionStorage.getItem('locationName')}
                        </Typography>
                    </ListItem>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className={classes.privacyPolicy}>
                    <Checkbox
                        color="secondary"
                        name="I agree to the privacy policy"
                        value="0"
                        checked={isAgreePrivacy}
                        onChange={(e) => { handleAgreePrivacyPolicy(e) }}
                    />
                    <Link className={classes.privacyLink}
                        onClick={handleChangePrivacyPolicy}
                        variant="body2"
                        component="button"
                        color="primary"
                        align="left"
                    >
                        {languageText.iAgree[language]} {/* {languageText.privacyPolicy[language]} */}
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
};

function mapStateToProps({ common, privacyPolicy }) {
    let { language } = common;
    let { isAgreePrivacy } = privacyPolicy;
    return { 
        language: sessionStorage.getItem('language') || language,
        isAgreePrivacy
    };
}

export default connect(mapStateToProps, {changePrivacyPolicy})(Registration);
