import { call, all, put, takeLatest } from 'redux-saga/effects';

import registrationApi from '../services/registrationApi';

import { 
    FETCH_CENTERS, 
    SUBMIT_FORM_REGISTRATION,
    FETCH_VACCINE_TYPE,
} from '../actions/types';

import {
    fetchCentersSuccess,
    fetchCentersFailed,
    submitFormRegistrationSuccess,
    submitFormRegistrationFailed,
    fetchVaccineTypeSuccess,
    fetchVaccineTypeFailed,
} from '../actions/registration';

function* fetchCenters() {
    try {
        const data = yield call(registrationApi.centers.getCenters);
        if (data.status === 'unsuccess') {
            yield put(fetchCentersFailed());
        } else {
            yield put(fetchCentersSuccess(data));
        }
    } catch (err) {
        yield put(fetchCentersFailed(err));
    }
}

/** Fetch vaccine types */
function* fetchVaccineTypes() {
    try {
        const data = yield call(registrationApi.getVaccineType.vaccineType);
        if (data.status) {
            yield put(fetchVaccineTypeSuccess(data));
        } else {
            yield put(fetchVaccineTypeFailed(data));
        }
    } catch (err) {
        let data = {
            "data": {},
            "status": true,
            "error_msg": "Fetch vaccine type failed.",
            "timestamp": "2021.06.12.21.00.06",
            "error_code": ""
        }
        yield put(fetchVaccineTypeFailed(data));
    }
}

function* submitFormRegistration({ payload }) {
    try {
        const data = yield call(registrationApi.registration.submit, payload);
        yield put(submitFormRegistrationSuccess(data));
    } catch (err) {
        yield put(submitFormRegistrationFailed(err));
    }
}

function* watchRegistrationActions() {
    yield takeLatest(FETCH_CENTERS, fetchCenters);
    yield takeLatest(SUBMIT_FORM_REGISTRATION, submitFormRegistration);
    yield takeLatest(FETCH_VACCINE_TYPE, fetchVaccineTypes);
}

export default function* registrationSaga() {
    yield all([watchRegistrationActions()]);
}
