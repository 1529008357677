const signIn = {
    signIn: {
        english: 'Sign in',
        sinhala: 'ඇතුළුවන්න',
        tamil: 'உள்நுழைக',
    },
    otpEnter: {
        english: 'Enter your OTP code',
        sinhala: 'ඔබගේ OTP කේතය ඇතුළත් කරන්න',
        tamil: 'உங்கள் OTP குறியீட்டை உள்ளிடவும்',
    },
    mobileNumberExample: {
        english: 'Mobile number in (07########) format',
        sinhala: 'ජංගම දුරකථන අංකය (07########) ආකෘතියෙන්',
        tamil: 'மொபைல் எண் (07 ########) வடிவத்தில்',
    },
    mobileNumberHelper: {
        english: 'Enter your mobile number in (07########) format and get OTP to login.',
        sinhala: 'ඔබගේ ජංගම දුරකථන අංකය (07 ########) ආකෘතියෙන් ඇතුලත් කර පිවිසීමට OTP ලබා ගන්න.',
        tamil: 'உங்கள் மொபைல் எண்ணை (07 ########) வடிவத்தில் உள்ளிட்டு உள்நுழைய OTP ஐப் பெறுக.',
    },
    sendOtpCode: {
        english: 'Send OTP Code',
        sinhala: 'OTP කේතය යවන්න',
        tamil: 'OTP குறியீட்டை அனுப்பவும்',
    },
    otpSentMessagePartOne: {
        english: 'A verification code has been sent to "07####',
        sinhala: 'සත්‍යාපන කේතයක් "07####',
        tamil: 'சரிபார்ப்புக் குறியீடு "07####',
    },
    otpSentMessagePartTwo: {
        english: '".',
        sinhala: '" වෙත යවා ඇත.',
        tamil: '" க்கு அனுப்பப்பட்டுள்ளது.',
    },
    didNotReceiveCode: {
        english: "If you didn't receive your verification,",
        sinhala: 'ඔබගේ සත්‍යාපනය ඔබට නොලැබුනේ නම්,',
        tamil: 'உங்கள் சரிபார்ப்பைப் பெறவில்லை எனில்,',
    },
    sendCodeAgain: {
        english: 'CLICK HERE to send again.',
        sinhala: 'නැවත යැවීමට මෙතැන ක්ලික් කරන්න.',
        tamil: 'மீண்டும் அனுப்ப இங்கே கிளிக் செய்க.',
    },
    retryIn: {
        english: 'retry in',
        sinhala: 'නැවත උත්සාහ කරන්න',
        tamil: 'மீண்டும் முயற்சிக்கவும்',
    },
    seconds: {
        english: 'seconds',
        sinhala: 'තත්පර',
        tamil: 'விநாடிகள்',
    },
    clickHere: {
        english: 'Click Here',
        sinhala: 'මෙහි ක්ලික් කරන්න',
        tamil: 'இங்கே கிளிக் செய்க',
    },
    please: {
        english: 'Please',
        sinhala: 'කරුණාකර',
        tamil: 'தயவு செய்து',
    },
    startOver: {
        english: 'to start over',
        sinhala: 'නැවත ආරම්භ කිරීමට',
        tamil: 'தொடங்குவதற்கு',
    },
    backButton:{
        english: 'Back',
        sinhala: 'ආපසු',
        tamil: 'மீண்டும்',
    },
    centerListDisplayName :{
        english: 'Available Vaccination Sessions for Registration',
        sinhala: 'ලියාපදිංචි කිරීම සඳහා ලබා ගත හැකි එන්නත් සැසි',
        tamil: 'பதிவு செய்வதற்கான தடுப்பூசி அமர்வுகள் கிடைக்கின்றன',
    },
    noContent: {
        english: 'There are no vaccination sessions for registration',
        sinhala: 'ලියාපදිංචි කිරීම සඳහා එන්නත් කිරීමේ සැසි නොමැත',
        tamil: 'பதிவு செய்ய தடுப்பூசி அமர்வுகள் இல்லை',
    },
    date:{
        english: 'Date',
        sinhala: 'දිනය',
        tamil: 'தேதி',
    },
    centerName:{
        english: 'Center Name',
        sinhala: 'මධ්‍යස්ථානයේ නම',
        tamil: 'மையத்தின் பெயர்',
    },
    /* vaccineType:{
        english: 'Vaccine Type',
        sinhala: 'එන්නත් වර්ගය',
        tamil: 'தடுப்பூசி வகை',
    }, */
    vaccineType:{
        english: 'Vaccine Dose',
        sinhala: 'එන්නත මාත්‍රාව',
        tamil: 'தடுப்பூசி டோஸ்',
    }, 
    notAvailableWaring:{
        english: 'Citizen Registration Portal will be open for registrations soon.',
        sinhala: 'පුරවැසි ලියාපදිංචි කිරීමේ ද්වාරය ළඟදීම ලියාපදිංචිය සඳහා විවෘත වේ.',
        tamil: 'குடிமக்கள் பதிவு போர்டல் விரைவில் பதிவுகளுக்கு திறக்கப்படும்.',
    },
    ageGroup:{
        english: 'Age Group',
        sinhala: 'වයස් කාණ්ඩය',
        tamil: 'வயதுக் குழு',
    },
    warningMsg:{
        english: 'If you are not a resident of this Grama Niladari area or if you are not a registered citizen in this Grama Niladari area, you will not be eligible for vaccination at this center. In case your NIC residential address is not within this Grama Niladari area, you will be required to provide a billing proof to prove your residency in that Grama Niladari area.',
        sinhala: 'ඔබ මෙම ග්‍රාම නිලදාරි ප්‍රදේශයේ පදිංචිකරුවෙකු නොවේ නම් හෝ ඔබ මෙම ග්‍රාම නිලදාරි ප්‍රදේශයේ ලියාපදිංචි පුරවැසියෙකු නොවේ නම්, ඔබට මෙම මධ්‍යස්ථානයේ එන්නත ලබා ගැනීමට සුදුසුකම් නොලැබේ. ඔබේ ජාතික හැඳුනුම්පතෙහි පදිංචි ලිපිනය මෙම ග්‍රාම නිලදාරි ප්‍රදේශය තුළ නොමැති නම්, එම ග්‍රාම නිලදාරි ප්‍රදේශයේ ඔබ පදිංචිව සිටින බව සනාථ කිරීම සඳහා බිල්පතක සාක්ෂියක් ඉදිරිපත් කිරීම අවශ්‍ය වේ.',
        tamil: 'நீங்கள் இந்த கிராம நிலதாரி பகுதியில் வசிப்பவராக இல்லாவிட்டால் அல்லது இந்த கிராம நிலதாரி பகுதியில் பதிவுசெய்யப்பட்ட குடிமகனாக இல்லாவிட்டால், இந்த மையத்தில் தடுப்பூசி போட நீங்கள் தகுதி பெற மாட்டீர்கள். உங்கள் என்.ஐ.சி குடியிருப்பு முகவரி இந்த கிராம நிலதாரி பகுதிக்குள் இல்லையென்றால், அந்த கிராம நிலாதரி பகுதியில் உங்கள் வதிவிடத்தை நிரூபிக்க பில்லிங் ஆதாரத்தை நீங்கள் வழங்க வேண்டும்.',
    }
};

export default signIn;
