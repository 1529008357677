import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography, Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import languageText from 'languages/common';
import Button from '@material-ui/core/Button';
import { logout } from 'actions/signin';

import { changeLanguage } from 'actions/common';
import inMemoryJWT from '../../../utils/inMemoryJwt';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        zIndex: 100,
        padding: '20px',
        position: 'inherit',
    },
    flexGrow: {
        flexGrow: 1,
    },
    img: {
        width: '60px',
        height: 'auto',
    },
    logoWrapper: {
        width: '100%',
        display: 'flex',
    },
    mainFont: {
        color: '#004F8B',
        'font-size': '20px',
        'font-weight': 1000,
    },
    textBox: {
        padding: '10px',
    },
    subtitle: {
        color: '#848484',
    },
    language: {
        width: '120px',
    },
    profile: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        justifyContent: 'space-between',
    },
    logout: {
        color: '#004F8B',
    },
}));

const Topbar = ({ logout, language, changeLanguage,loginSuccess }) => {
    const classes = useStyles();

    const handleLogout = () => {
        //inMemoryJWT.ereaseToken();
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('loginSuccess');
        sessionStorage.removeItem('mobile');
        window.location = '/sign-in';
        logout();
    };

    const handleClickLogo = () => {
        sessionStorage.removeItem('fullName');
        sessionStorage.removeItem('nic');
        sessionStorage.removeItem('dob');
        sessionStorage.removeItem('gender');
        sessionStorage.removeItem('allergies');
        sessionStorage.removeItem('chronicDisease');
        sessionStorage.removeItem('comorbidities');
        sessionStorage.removeItem('firstVaccine');
        sessionStorage.removeItem('firstVaccineDate');
        sessionStorage.removeItem('vaccinationCenterID');
        sessionStorage.removeItem('vaccinationCenterName');
        sessionStorage.removeItem('vaccinationCenter');
    }

    const handleChangeLanguage = (value) => {
        changeLanguage(value);
    }

    const [tokenIsAvailable,setSokenIsAvailable] = useState(false);
    useEffect(()=>{
        sessionStorage.hasOwnProperty("token") && sessionStorage.getItem('token') !== null ? setSokenIsAvailable(true) : setSokenIsAvailable(false)
    },[sessionStorage,loginSuccess])

    return (
        <AppBar className={clsx(classes.root)}>
            <Toolbar className={clsx(classes.toolBar)}>
                <Grid container justify="center" spacing={4}>
                    <Grid item lg={10} md={10} sm={10} xs={12}>
                        <div className={classes.logoWrapper}>
                             <RouterLink to="/registration-list" onClick={handleClickLogo}>
                                <img
                                    
                                    className={classes.img}
                                    alt="Logo"
                                    src="/images/logos/moh-logo-2.png"
                                />
                           </RouterLink> 
                            <div className={classes.textBox}>
                                <Typography
                                    variant="h3"
                                    className={classes.mainFont}
                                >
                                    {languageText.covid19[language]}
                                </Typography>
                                <Typography
                                    variant="h3"
                                    className={classes.mainFont}
                                >
                                    {languageText.vaccinationProgram[language]}
                                </Typography>
                                <Typography
                                    inline="true"
                                    component="h2"
                                    variant="h5"
                                    className={classes.subtitle}
                                >
                                    {languageText.topBarTitle[language]}
                                </Typography>
                            </div>

                            <br />
                        </div>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={12}>
                        <div className={classes.profile}>
                            <div>
                                <FormControl variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        {languageText.selectLanguage[language]}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={language}
                                        onChange={event =>
                                            handleChangeLanguage(event.target.value)
                                        }
                                        label="Select Language"
                                        className={classes.language}
                                    >
                                        <MenuItem value={'english'}>
                                            English
                                        </MenuItem>
                                        <MenuItem value={'sinhala'}>
                                            සිංහල
                                        </MenuItem>
                                        <MenuItem value={'tamil'}>
                                            தமிழ்
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                               {tokenIsAvailable ? ( 
                                    <Button
                                        className={classes.logout}
                                        onClick={handleLogout}
                                    >
                                        {languageText.logout[language]}
                                    </Button>
                                 ) : null}
                            </div>
                        </div>
                    </Grid>
                </Grid>
{/* {console.log("tokenIsAvailable =====================" , tokenIsAvailable)} */}
                <div className={classes.flexGrow} />
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
};

function mapStateToProps({ common, signin }) {
    let { language } = common;
    let {loginSuccess} = signin;
    return {
        loginSuccess,
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, { changeLanguage, logout })(Topbar);
