import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Redirect, useLocation } from 'react-router-dom';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { fetchCertificate } from 'actions/certificateView';
import ErrorDisplay from 'views/Common/ErrorDisplay';
import Spinner from 'views/Common/Spinner';
import languageText from 'languages/certificateView';
import { MenuItem } from '@material-ui/core';

import './index.css'

const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    pdfMainComponent: {
        display: 'flex',
        justifyContent: 'center',
        width:'100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        height: '51px',
    },
    buttonContainer : {
       /*  [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
           display: 'grid'
        }, */
        display: 'flex',
    },
    certificate:{
        canvas: {
            width: '100% !important',
        }
    }
}));

const CertificateView = ({
    loading,
    error,
    fetchCertificate,
    loginSuccess,
    loginSuccessTime,
    data,
    language,
}) => {
    const location = useLocation();

    useEffect(() => {
        const user = location.state.user;
        const getUser = {
            "id": null,
            "firstName": user.name,
            "lastName": null,
            "address": null,
            "nic": user.nic ,
            "gender": user.gender?.gender,
            "age": null,
            "dob": null,
            "vaccineName": null,
            "dateOfDose": null,
            "nextDueDate": null,
            "vaccinatedBy": null,
            "vaccinationAt": null,
            "mobileNumber": user.mobile ? user.mobile:null,
            "batchNumber": null
        }
        fetchCertificate(getUser);
    }, [location.state.user])

    /* if (new Date().valueOf() - loginSuccessTime > 7200000) {
         loginSuccess = false;
         sessionStorage.clear();
     }  */

    const classes = useStyles();
    const [certificateUrl, setcertificateUrl] = useState(null);

    const [select, setSelect] = useState('');
    useEffect(() => {
        const file = new Blob(
            [data?.data.certData],
            { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setcertificateUrl(fileURL)
    }, [data]);

   // const [numPages, setNumPages] = useState(null);
    function onDocumentLoadSuccess({ numPages }) {
        //setNumPages(numPages);
    }

    /* const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    } */


    const handleChange = (event) => {
        const name = event.target.name;
          setSelect({
           ...select,
           [name]: event.target.value,
         }); 
        if (event.target.value === 'pdf') {
            downloadAsPdf();
        } else {
            downloadAsImage();
        }
    };

    const downloadAsImage = () => {
        const certificateDiv = document.getElementById("certificate");
        const url = certificateDiv.firstChild.firstChild.firstChild.toDataURL("image/png")
        let link = document.createElement('a');
        link.download = "Vaccination_Certificate_" + ".png";
        link.href = url;
        link.click();
    }

    const downloadAsPdf = () => {
        var link = document.createElement('a');
        link.download = "Vaccination_Certificate_" + ".pdf";
        link.href = certificateUrl
        link.click();
    }

    const handleNewRegistration = () => {
        sessionStorage.removeItem("nic");
    };

    return !loginSuccess ? (
        <Redirect to="/sign-in" />
    ) : loading ? (
        <Spinner></Spinner>
    ) : error ? (
        <ErrorDisplay error={error} backButton={true}></ErrorDisplay>
    ) : (
        <div className={classes.root}>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        {languageText.certificate[language]}
                    </Typography>
                    <div id="certificate" className={classes.certificate}>
                        <Document style={{
                            width: '100% !important',
                            height: '100% !important',
                        }} className={classes.pdfMainComponent}
                            file={`${certificateUrl}`}
                            // file={{ url: 'http://localhost:8080/cz-portal-backend-service/api/v1/cert/api/certificatePDF' }}
                            //onLoadSuccess={onDocumentLoadSuccess}
                            //file={certificateUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                        ><Page
                                pageNumber={1}
                        />

                        </Document>
                        <div className={classes.buttonContainer}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="outlined-age-native-simple">{languageText.download[language]}</InputLabel>
                                <Select
                                    // native
                                    //value={select}
                                    onChange={handleChange}
                                    label={languageText.download[language]}
                                    inputProps={{
                                        name: 'Download',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <em> <MenuItem value={""} disabled>{languageText.download[language]}</MenuItem> </em>
                                    <MenuItem value={"image"}>{languageText.asImage[language ]}</MenuItem>
                                    <MenuItem value={"pdf"}>{languageText.asPdf[language]}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Button className={classes.buttons}
                                    variant="contained"
                                    href="/registration-list"
                                    className={classes.button}
                                    onClick={handleNewRegistration}
                                >
                                    {languageText.viewRegistrations[language]}
                                </Button>
                            </FormControl>
                        </div>
                    </div>
                </Paper>
            </main>
        </div>
    );
};

function mapStateToProps({ common, certificate }) {
    let { loading, error, data } = certificate;
    let loginSuccess = sessionStorage.getItem('loginSuccess');
    let loginSuccessTime = sessionStorage.getItem('loginSuccessTime');
    let { language } = common;
    
     if(certificate.data?.status === 204){
        error = "certificateNotFound"
    } 
    
    return {
        loading,
        error,
        loginSuccess,
        loginSuccessTime,
        data,
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, { fetchCertificate })(CertificateView);
