import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BasicRegistration from './components/BasicRegistration';
import CenterSelection from './components/CenterSelection';
import Review from './components/Confirmation';
import Grid from '@material-ui/core/Grid';
import { Redirect,useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import dateFormat from 'dateformat';

import { submitFormRegistration } from 'actions/registration';
import ErrorDisplay from 'views/Common/ErrorDisplay';
import Spinner from 'views/Common/Spinner';
import languageText from 'languages/registration';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
        overflowWrap: "anywhere"
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const Registration = ({
    loading,
    error,
    submitFormRegistration,
    language,
    confirmation,
    loginSuccess,
    loginSuccessTime,
    isAgreePrivacy,
    isCheckedRecapcha,
}) => {
    const classes = useStyles();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [validationMessage, setValidationMessage] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <BasicRegistration />;
            case 1:
                return <CenterSelection />;
            case 2:
                return <Review />;
            default:
                throw new Error('Unknown step');
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleRegistrationSubmission = () => {
        handleNext();
        submitFormRegistration({
            name: sessionStorage.getItem('fullName'),
            nic: sessionStorage.getItem('nic'),
            dob: moment(sessionStorage.getItem('dob')).format('DD-MM-YYYY'),//dateFormat(sessionStorage.getItem('dob'), 'd/mm/yyyy'),
            gender: sessionStorage.getItem('gender'),
            allergies: sessionStorage.getItem('allergies') ? 'Y' : 'N',
            chronicDisease: sessionStorage.getItem('chronicDisease')
                ? 'Y'
                : 'N',
            chronicDiseaseDetails: sessionStorage.getItem('comorbidities')
                ? JSON.parse(sessionStorage.getItem('comorbidities'))
                : [],
            receivedFirstDose:
                sessionStorage.getItem('firstVaccine') === 'yes' ? 'Y' : 'N',
            firstDoseDate: moment(sessionStorage.getItem('firstVaccineDate')).format('DD-MM-YYYY')
            /* dateFormat(
                sessionStorage.getItem('firstVaccineDate'),
                'DD-MM-YYYY'
            ) */,
            mobile: sessionStorage.getItem('mobile'),
            districtId: sessionStorage.getItem('districtId'),
            districtName: sessionStorage.getItem('districtName'),
            locationId: sessionStorage.getItem('locationId'),
            locationName: sessionStorage.getItem('locationName'),
            vCenterid: sessionStorage.getItem('mohId'),
            vCenterName: sessionStorage.getItem('mohName'),
            vaccineTypeId: sessionStorage.getItem('vaccineTypeId'),
            vaccineTypeName: sessionStorage.getItem('vaccineTypeName'),
        });
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const oldNICRegex = new RegExp('^[1-9][0-9]{8}[X,V,x,v]$');
    const newNICRegex = new RegExp('^[18,19,20,21]{2}[0-9]{10}$');
    const nameRegex = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$");
    const lastWhiteSpaceRegex = new RegExp("\s+$");

    const handleNextBasicDetails = () => {
        let tempNic = sessionStorage.getItem('nic');
        let tempFullName = sessionStorage.getItem('fullName');

        if(tempFullName !== null && !lastWhiteSpaceRegex.test(tempFullName)){
            tempFullName = tempFullName.slice(0, -1);
        }

        if (
            activeStep === 0 &&
            (!sessionStorage.getItem('nic') ||
                (!oldNICRegex.test(tempNic) && !newNICRegex.test(tempNic)))
        ) {
            setValidationMessage(languageText.enterValidNIC[language]);
            handleClickOpen();
        } else if (activeStep === 0 && (!sessionStorage.getItem('fullName')) || (!nameRegex.test(tempFullName))) {
            setValidationMessage(languageText.enterValidName[language]);
            handleClickOpen();
        } else if (activeStep === 0 && !sessionStorage.getItem('gender')) {
            setValidationMessage(languageText.selectValidGender[language]);
            handleClickOpen();
        } else if (activeStep === 0 && !sessionStorage.getItem('dob')) {
            setValidationMessage(languageText.selectValidDob[language]);
            handleClickOpen();
        } else if (
            activeStep === 0 &&
            sessionStorage.getItem('firstVaccine') === null
        ) {
            setValidationMessage(
                languageText.selectValidFirstVaccineDate[language]
            );
            handleClickOpen();
        }
         else if (
            activeStep === 0 &&
            sessionStorage.getItem('firstVaccine') === 'yes' &&
            !sessionStorage.getItem('firstVaccineDate')
        ) {
            setValidationMessage(
                languageText.selectValidFirstVaccineDate[language]
            );
            handleClickOpen();
        } /* else if (
            activeStep === 0 &&
            !sessionStorage.getItem('vaccineType') &&
            sessionStorage.getItem('firstVaccine') === 'yes'
        ) {
            setValidationMessage(
                languageText.selectValidVaccineType[language]
            );
            handleClickOpen();
        } */
        else if (
            activeStep === 1 && (
                !sessionStorage.getItem('district') ||
                !sessionStorage.getItem('moh') ||
                !sessionStorage.getItem('location')
            )
        ) {
            setValidationMessage(languageText.selectVaccineCenter[language]);
            handleClickOpen();
        } else {
            handleNext();
        }
    };

    const handleNewRegistration = () => {
        sessionStorage.removeItem('fullName');
        sessionStorage.removeItem('nic');
        sessionStorage.removeItem('dob');
        sessionStorage.removeItem('gender');
        sessionStorage.removeItem('allergies');
        sessionStorage.removeItem('chronicDisease');
        sessionStorage.removeItem('comorbidities');
        sessionStorage.removeItem('firstVaccine');
        sessionStorage.removeItem('firstVaccineDate');
        /** Vaccine type */
        sessionStorage.removeItem('vaccineType');
        sessionStorage.removeItem('vaccineTypeId');
        sessionStorage.removeItem('vaccineTypeName');
        /** districts */
        sessionStorage.removeItem('districtId');
        sessionStorage.removeItem('districtName');
        sessionStorage.removeItem('district');
        /** mohCenter */
        sessionStorage.removeItem('moh');
        sessionStorage.removeItem('mohName');
        sessionStorage.removeItem('mohId');
        /** center Location */
        sessionStorage.removeItem('locationId');
        sessionStorage.removeItem('locationName');
        sessionStorage.removeItem('location');
    };

     /* if (new Date().valueOf() - loginSuccessTime > 7200000) {
        loginSuccess = false;
        sessionStorage.clear();
    }  */

    const handleBackToHome = () => {
        history.push("/registration-list");
        handleNewRegistration();
    }

    return !loginSuccess ? (
        <Redirect to="/sign-in" />
    ) : loading ? (
        <Spinner></Spinner>
    ) : error ? (
        <ErrorDisplay error={error}></ErrorDisplay>
    ) : (
        <div className={classes.root}>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h3" align="center">
                        {languageText.userRegistrations[language]}
                    </Typography>
                    <Stepper
                        activeStep={activeStep}
                        className={classes.stepper}
                    >
                        <Step>
                            <StepLabel>
                                {languageText.personalDetails[language]}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>
                                {languageText.vaccinationCenter[language]}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>
                                {languageText.reviewYourDetails[language]}
                            </StepLabel>
                        </Step>
                    </Stepper>
                    <React.Fragment>
                        {activeStep === 3 ? (
                            confirmation.loading ? (
                                <Spinner></Spinner>
                            ) : confirmation.error ? (
                                <ErrorDisplay
                                    error={confirmation.error}
                                    errorDescription={ confirmation.errorDescription }
                                    refreshButton={true}
                                ></ErrorDisplay>
                            ) : (
                                <React.Fragment>
                                    <Typography variant="h4" gutterBottom>
                                        {
                                            languageText.appointmentSuccess[
                                                language
                                            ]
                                        }
                                    </Typography>
                                    <br />
                                    <Typography variant="h5" gutterBottom>
                                        {/* {`${languageText.appointmentNumber[language]}: ${confirmation.appointmentNumber}`} */}
                                    </Typography>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className={classes.title}
                                            >
                                                {
                                                    languageText.userDetails[
                                                        language
                                                    ]
                                                }
                                            </Typography>
                                            <Typography gutterBottom>
                                                {confirmation.name}
                                            </Typography>
                                            <Typography gutterBottom>
                                                {confirmation.nic}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            xs={12}
                                            sm={6}
                                        >
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className={classes.title}
                                            >
                                                {
                                                    languageText.centerDetails[
                                                        language
                                                    ]
                                                }
                                            </Typography>
                                            <Grid container>
                                                <React.Fragment>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            gutterBottom
                                                        >
                                                            {
                                                                languageText
                                                                    .center[
                                                                    language
                                                                ]
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            gutterBottom
                                                        >
                                                            {
                                                                confirmation.location
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </React.Fragment>
                                                <React.Fragment>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            gutterBottom
                                                        >
                                                            {
                                                                languageText.location[language]
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            gutterBottom
                                                        >
                                                            {
                                                                confirmation.locationName
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </React.Fragment>
                                                <React.Fragment>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            gutterBottom
                                                        >
                                                            {
                                                                languageText
                                                                    .date[
                                                                    language
                                                                ]
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            gutterBottom
                                                        >
                                                            {moment(new Date(confirmation.date)).format('DD-MM-YYYY')}
                                                            {/* {confirmation.date} */} 
                                                        </Typography>
                                                    </Grid>
                                                </React.Fragment>
                                                <React.Fragment>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            gutterBottom
                                                        >
                                                            {
                                                                languageText
                                                                    .timeSlot[
                                                                    language
                                                                ]
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            gutterBottom
                                                        >
                                                            {
                                                                confirmation.timeslot
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </React.Fragment>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <div className={classes.buttons}>
                                        <Button
                                            variant="contained"
                                            href="/registration-list"
                                            className={classes.button}
                                            onClick={handleNewRegistration}
                                        >
                                            {
                                                languageText.viewRegistrations[
                                                    language
                                                ]
                                            }
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href="/registration"
                                            className={classes.button}
                                            onClick={handleNewRegistration}
                                        >
                                            {
                                                languageText.newRegistration[
                                                    language
                                                ]
                                            }
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep)}
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            {languageText.back[language]}
                                        </Button>
                                    )}
                                    {activeStep === 2 ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                                handleRegistrationSubmission
                                            }
                                            disabled = {!isAgreePrivacy}
                                            className={classes.button}
                                        >
                                            {
                                                languageText.getAppointment[
                                                    language
                                                ]
                                            }
                                        </Button>
                                    ) : activeStep === 1 ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNextBasicDetails}
                                            className={classes.button}
                                        >
                                            {
                                                languageText.reviewYourDetails[
                                                    language
                                                ]
                                            }
                                        </Button>
                                    ) : (
                                        <div>
                                            <Button
                                                onClick={handleBackToHome}
                                                className={classes.button}
                                            >
                                                {languageText.back[language]}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNextBasicDetails}
                                                className={classes.button}
                                            >
                                                {
                                                    languageText
                                                        .selectVaccinationCenter[
                                                        language
                                                    ]
                                                }
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </main>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {languageText.validationError[language]}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {validationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {languageText.close[language]}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

function mapStateToProps({ signin, registration, common, privacyPolicy }) {
    let { mobile } = signin;
    let { confirmation } = registration;
    let loginSuccess = sessionStorage.getItem('loginSuccess');
    let loginSuccessTime = sessionStorage.getItem('loginSuccessTime');
    let { language } = common;
    let { isAgreePrivacy, isCheckedRecapcha } = privacyPolicy
    return {
        mobile,
        confirmation,
        loginSuccess,
        loginSuccessTime,
        isAgreePrivacy,
        isCheckedRecapcha,
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, { submitFormRegistration })(
    Registration
);
