import axios from 'axios'; 

export default {
    certificate: {
        submit: payload =>
            axios
                .post(`/cert/api/getCertificateByDhis`  ,payload  ,{
                   // responseType: 'blob'
                })
                .then(res =>res),
    },
};