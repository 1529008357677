const common = {
    covid19: {
        english: 'COVID-19',
        sinhala: 'COVID-19',
        tamil: 'COVID-19',
    },
    vaccinationProgram: {
        english: 'Vaccination Program',
        sinhala: 'එන්නත් කිරීමේ වැඩසටහන',
        tamil: 'தடுப்பூசி திட்டம்',
    },
    topBarTitle: {
        english: 'Sri Lanka COVID-19 Vaccination Program - Citizen Registration Portal | ICTA',
        sinhala: 'ශ්‍රී ලංකා COVID-19 එන්නත් කිරීමේ වැඩසටහන - පුරවැසි ලියාපදිංචි කිරීමේ ද්වාරය | ICTA',
        tamil: 'இலங்கை COVID -19 தடுப்பூசி திட்டம் - குடிமக்கள் பதிவு போர்டல் | ICTA',
    },
    selectLanguage: {
        english: 'Select Language',
        sinhala: 'භාෂාව තෝරන්න',
        tamil: 'மொழியை தேர்ந்தெடுங்கள்',
    },
    logout: {
        english: 'LOGOUT',
        sinhala: 'ඉවත් වන්න',
        tamil: 'வெளியேறு',
    },
};

export default common;
