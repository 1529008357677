const registrationList = {
    previousRegistrations: {
        english: 'Previous Registrations',
        sinhala: 'පෙර ලියාපදිංචි',
        tamil: 'முந்தைய பதிவு',
    },
    noRegistrations: {
        english: 'No Registration',
        sinhala: 'ලියාපදිංචි නැත',
        tamil: 'பதிவு இல்லை',
    },
    nic: {
        english: 'NIC',
        sinhala: 'ජාතික හැඳුනුම්පත',
        tamil: 'தே. அ. அ',
    },
    name: {
        english: 'Name',
        sinhala: 'නම',
        tamil: 'பெயர்',
    },
    vaccinationCenter: {
        english: 'Vaccination Center',
        sinhala: 'එන්නත් කිරීමේ මධ්‍යස්ථානය',
        tamil: 'தடுப்பூசி நிலையம்',
    },
    date: {
        english: 'Date',
        sinhala: 'දිනය',
        tamil: 'திகதி',
    },
    timeSlot: {
        english: 'Time Slot',
        sinhala: 'කාල පරාසය',
        tamil: 'ஒதுக்கப்பட்ட நேரம்',
    },
    action: {
        english: 'Action',
        sinhala: 'කටයුතු',
        tamil: 'நடவடிக்கை',
    },
    remove: {
        english: 'Remove',
        sinhala: 'ඉවත් කරන්න',
        tamil: 'அகற்று',
    },
    viewCertificate: {
        english: 'View Certificate',
        sinhala: 'සහතිකය බලන්න',
        tamil: 'சான்றிதழைக் காண்க',
    },
    makeNewRegistration: {
        english: 'Make New Registration',
        sinhala: 'නව ලියාපදිංචි කිරීමක් කරන්න',
        tamil: 'புதிய பதிவு செய்யுங்கள்',
    },
    downLoadCertificate: {
        english: 'Download Certificate',
        sinhala: 'සහතිකය බාගන්න',
        tamil: 'சான்றிதழைப் பதிவிறக்குக',
    },
    appointmentDisabled:{
        english: 'Make new registrations temporarily unavailable.',
        sinhala: 'නව ලියාපදිංචිය තාවකාලිකව ලබා ගත නොහැක.',
        tamil: 'புதிய பதிவுகளை தற்காலிகமாக கிடைக்கச் செய்யுங்கள்.',
    },
    downloadCertificateToolTip: {
        english: 'The download certificate feature will be made available soon for those who have gotten two doses.',
        sinhala: 'මාත්‍රා දෙකක් ලබා ඇති අය සඳහා සහතික ලබාගැනීමේ පහසුකම ඉක්මනින් ලබා දෙනු ඇත.',
        tamil: 'இரண்டு டோஸ் பெற்றவர்களுக்கு பதிவிறக்க சான்றிதழ் அம்சம் விரைவில் கிடைக்கும்.',
    },
    dose: {
        english: 'Vaccination Dose',
        sinhala: 'එන්නත් මාත්‍රාව',
        tamil: 'தடுப்பூசி அளவு',
    },
    registarionFlowMsg: {
        english: 'The new Registrations feature will be made available soon.',
        sinhala: 'නව ලියාපදිංචි කිරීමේ අංගය ළඟදීම ලබා දෙනු ඇත.',
        tamil: 'புதிய பதிவு அம்சம் விரைவில் கிடைக்கும்.',
    },
};

export default registrationList;
