const common = {
    personalDetails: {
        english: 'Personal Details',
        sinhala: 'පුද්ගලික තොරතුරු',
        tamil: 'தனிப்பட்ட விபரங்கள்',
    },
    vaccinationCenter: {
        english: 'Vaccination Center',
        sinhala: 'එන්නත් කිරීමේ මධ්‍යස්ථානය',
        tamil: 'தடுப்பூசி நிலையம்',
    },
    reviewYourDetails: {
        english: 'Review Your Details',
        sinhala: 'ඔබේ විස්තර සමාලෝචනය කරන්න',
        tamil: 'உங்கள் விபரங்களை மதிப்பாய்வு செய்யவும்',
    },
    userRegistrations: {
        english: 'User Registration',
        sinhala: 'පරිශීලක ලියාපදිංචිය',
        tamil: 'பயனாளர் பதிவு',
    },
    appointmentSuccess: {
        english: 'Your vaccination appointment has been scheduled successfully',
        sinhala:
            'එන්නත්කරණය සඳහා ඔබට දිනයක් හා වේලාවක් වෙන් කිරීම සාර්ථකව සිදු කරන ලදී',
        tamil: 'உங்கள் தடுப்பூசி நியமனம் வெற்றிகரமாக திட்டமிடப்பட்டுள்ளது',
    },
    appointmentNumber: {
        english: 'Appointment Number',
        sinhala: 'පත්වීම් අංකය',
        tamil: 'நியமனம் எண்',
    },
    userDetails: {
        english: 'User Details',
        sinhala: 'පරිශීලක විස්තර',
        tamil: 'பயனர் விபரங்கள்',
    },
    centerDetails: {
        english: 'Vaccination Center Details',
        sinhala: 'එන්නත් මධ්‍යස්ථාන පිළිබඳ විස්තර',
        tamil: 'தடுப்பூசி நிலைய விபரங்கள்',
    },
    center: {
        english: 'MOH Center',
        sinhala: 'MOH මධ්‍යස්ථානය',
        tamil: 'MOH நிலையம்',
    },
    location: {
        english: 'Location',
        sinhala: 'ස්ථානය',
        tamil: 'இடம்',
    },
    viewRegistrations: {
        english: 'View Registrations',
        sinhala: 'ලියාපදිංචි කිරීම් විස්තර බලන්න',
        tamil: 'பதிவுகளைக் பார்வையிட',
    },
    date: {
        english: 'Date',
        sinhala: 'දිනය',
        tamil: 'திகதி',
    },
    timeSlot: {
        english: 'Time Slot',
        sinhala: 'කාල පරාසය',
        tamil: 'ஒதுக்கப்பட்ட நேரம்',
    },
    back: {
        english: 'Back',
        sinhala: 'ආපසු',
        tamil: 'மீண்டும்',
    },
    getAppointment: {
        english: 'Get Appointment',
        sinhala: 'එන්නත්කරණය සඳහා වේලාවක් වෙන් කරගන්න.',
        tamil: 'நியமனம் பெற்றுக்கொள்ள',
    },
    newRegistration: {
        english: 'New Registration',
        sinhala: 'නව ලියාපදිංචිය',
        tamil: 'புதிய பதிவு',
    },
    selectVaccinationCenter: {
        english: 'Select Vaccination Center',
        sinhala: 'එන්නත් කිරීමේ මධ්‍යස්ථානය තෝරන්න',
        tamil: 'தடுப்பூசி நிமையத்தைத் தேர்ந்தெடுக்கவும்',
    },
    validationError: {
        english: 'Validation Error',
        sinhala: 'තහවුරු කිරීමේ දෝෂයකි',
        tamil: 'சரிபார்ப்பு பிழை',
    },
    close: {
        english: 'Close',
        sinhala: 'වසන්න',
        tamil: 'நெருக்கமான',
    },
    enterValidNIC: {
        english: 'Please enter a valid NIC',
        sinhala: 'කරුණාකර වලංගු ජාතික හැඳුනුම්පතක් ඇතුළත් කරන්න',
        tamil: 'செல்லுபடியாகும் தே. அ. அ இலக்கத்தை உள்ளிடவும்',
    },

    enterValidName: {
        english: 'Please enter a valid name',
        sinhala: 'කරුණාකර ඔබගේ න‍ම නිවැරදිව ඇතුළත් කරන්න',
        tamil: 'சரியான பெயரை உள்ளிடவும்',
    },
    selectValidGender: {
        english: 'Please select a valid gender',
        sinhala: 'කරුණාකර වලංගු ස්ත්‍රී පුරුෂ භාවය නිවැරදිව තෝරන්න',
        tamil: 'செல்லுபடியாகும் பாலினத்தைத் தேர்ந்தெடுக்கவும்',
    },
    selectValidDob: {
        english: 'Please select a valid date of birth',
        sinhala: 'කරුණාකර ඔබගේ උපන්දිනය නිවැරදිව තෝරන්න',
        tamil: 'பிறந்த திகதியைத் தேர்ந்தெடுக்கவும்',
    },
    selectValidFirstVaccineDate: {
        english: 'Please select a valid second vaccine date',
        sinhala: 'කරුණාකර දෙවන එන්නත් දිනය නිවැරදිව තෝරන්න',
        tamil: 'செல்லுபடியாகும் இரண்டாவது தடுப்பூசி திகதியைத் தேர்ந்தெடுக்கவும்',
    },
    selectVaccineCenter: {
        english: 'Please select a vaccination center',
        sinhala: 'කරුණාකර එන්නත් කිරීමේ මධ්‍යස්ථානයක් තෝරන්න',
        tamil: 'தடுப்பூசி நிலையத்தைத் தேர்ந்தெடுக்கவும்',
    },
    viewCertificate: {
        english: 'View Certificate',
        sinhala: 'සහතිකය බලන්න',
        tamil: 'சான்றிதழைக் காண்க',
    },
    certificate:{
        english: 'Certificate',
        sinhala: 'සහතිකය',
        tamil: 'சான்றிதழைக்',
    },
    selectValidVaccineType: {
        english: 'Please select a vaccine-type.',
        sinhala: 'කරුණාකර එන්නත් වර්ගයක් තෝරන්න.',
        tamil: 'தடுப்பூசி வகையைத் தேர்ந்தெடுக்கவும்.',
    },
};

export default common;
