const basicRegistation = {
    userDetails: {
        english: 'User Details',
        sinhala: 'පරිශීලක විස්තර',
        tamil: 'பயனர் விபரங்கள்',
    },
    nic: {
        english: 'National Identity Card',
        sinhala: 'ජාතික හැඳුනුම්පත',
        tamil: 'தேசிய அடையாள அட்டை',
    },
    nicHelperText: {
        english:
            'e.g: xxxxxxxxxV (old NIC format) or 199602235434 (new NIC format)',
        sinhala:
            'e.g: xxxxxxxxxV (පැරණි ජාතික හැඳුනුම්පත් ආකෘතිය) හෝ xxxxxxxxxxxx (නව ජාතික හැඳුනුම්පත් ආකෘතිය)',
        tamil:
            'e.g: xxxxxxxxxV (பழைய தே. அ. அ வடிவம்) அல்லது  xxxxxxxxxxxx (புதிய தே. அ. அ வடிவம்)',
    },
    fullName: {
        english: 'Full Name',
        sinhala: 'සම්පූර්ණ නම',
        tamil: 'முழு பெயர்',
    },
    selectGender: {
        english: 'Select Gender',
        sinhala: 'ස්ත්‍රී පුරුෂ භාවය තෝරන්න',
        tamil: 'பாலினத்தைத் தேர்ந்தெடுக்கவும்',
    },
    male: {
        english: 'Male',
        sinhala: 'පිරිමි',
        tamil: 'ஆண்',
    },
    female: {
        english: 'Female',
        sinhala: 'ගැහැණු',
        tamil: 'பெண்',
    },
    other: {
        english: 'Other',
        sinhala: 'වෙනත්',
        tamil: '​வேறு',
    },
    dob: {
        english: 'Date of Birth',
        sinhala: 'උපන් දිනය',
        tamil: 'பிறந்த திகதி',
    },
    allergies: {
        english: 'Allergies',
        sinhala: 'අසාත්මිකතා',
        tamil: 'ஒவ்வாமை',
    },
    hasAllergies: {
        english: 'Has Allergies',
        sinhala: 'අසාත්මිකතා ඇත',
        tamil: 'ஒவ்வாமை உள்ளது',
    },
    treatmentForChronic: {
        english: 'Taking treatment for any chronic disease',
        sinhala: 'ඕනෑම නිදන්ගත රෝගයකට ප්‍රතිකාර කිරීම',
        tamil: 'எந்தவொரு நாட்பட்ட நோய்க்கும் சிகிச்சை பெறுதல்',
    },
    cormobidities: {
        english: 'Comorbidities',
        sinhala: 'සහසම්බන්ධතා',
        tamil: 'கொமொர்பிடிட்டீஸ்',
    },
    diabetesMellitus: {
        english: 'Diabetes Mellitus',
        sinhala: 'දියවැඩියා රෝගය',
        tamil: 'நீரிழிவு நோய்',
    },
    hyperTension: {
        english: 'Hypertension',
        sinhala: 'අධි රුධිර පීඩනය',
        tamil: 'உயர் இரத்த அழுத்தம்',
    },
    heartDisease: {
        english: 'Ischaemic Heart Disease',
        sinhala: 'හෘද රෝග',
        tamil: 'இஸ்கிமிக் இதய நோய்',
    },
    asthma: {
        english: 'Bronchial Asthma',
        sinhala: 'බ්රොන්පයිල් ඇදුම',
        tamil: 'மூச்சுக்குழாய் ஆஸ்துமா',
    },
    airwayDisease: {
        english: 'Chronic Obstructive Airway Disease',
        sinhala: 'නිදන්ගත බාධාකාරී වාත රෝග',
        tamil: 'நாள்பட்ட தடுப்பு காற்றுப்பாதை நோய்',
    },
    kidneyDisease: {
        english: 'Chronic Kidney Disease',
        sinhala: 'නිදන්ගත වකුගඩු රෝගය',
        tamil: 'நாள்பட்ட சிறுநீரக நோய்',
    },
    mallignancies: {
        english: 'Malignancies',
        sinhala: 'අක්‍රමිකතා',
        tamil: 'தீங்கு விளைவிக்கும்',
    },
    firstVaccineReceived: {
        english: 'Have you received the second dose of vaccine',
        sinhala: 'ඔබට දෙවන එන්නත ලබා දී තිබේද?',
        tamil: 'நீங்கள் இரண்டாவது டோஸ் தடுப்பூசியைப் பெற்றுள்ளீர்களா?',
    },
    yes: {
        english: 'Yes',
        sinhala: 'ඔව්',
        tamil: 'ஆம்',
    },
    no: {
        english: 'No',
        sinhala: 'නැත',
        tamil: 'இல்லை',
    },
    firstVaccineDate: {
        english: 'Second Vaccine Date',
        sinhala: 'දෙවන එන්නත දිනය',
        tamil: 'இரண்டாவது தடுப்பூசி திகதி',
    },
   warningMsg:{
        english: 'To register for the third dose of  vaccine against Covid-19 virus, you must complete at least three months of time after receiving the second dose of the vaccine against Covid-19 virus.',
        sinhala: 'Covid-19 වෛරසයට එරෙහි එන්නතෙහි තුන්වන මාත්‍රාව සඳහා ලියාපදිංචි වීමට, Covid-19 වෛරසයට එරෙහි එන්නතෙහි දෙවන මාත්‍රාව ලබා ගැනීමෙන් පසු ඔබ අවම වශයෙන් මාස තුනක කාලයක් සම්පූර්ණකර තිබිය යුතුය.',
        tamil: 'முன்றாவது Covid-19 தடுப்பூசியை பெற்றுக்கொள்ள பதிவு செய்வதற்கு, நீங்கள் Covid-19 இரண்டாம் தடுப்பூசியை பெற்றுக்கொண்டு குறைந்தது மூன்று மாதங்கள் பூர்த்தி ஆகி இருத்தல் வேண்டும்.',
    },
    selectVaccineType: {
        english: 'Select Vaccine Type',
        sinhala: 'එන්නත් වර්ගය තෝරන්න',
        tamil: 'தடுப்பூசி வகையைத் தேர்ந்தெடுக்கவும்',
    },
};

export default basicRegistation;
