import {
    FETCH_CERTIFICATE,
    FETCH_CERTIFICATE_SUCCESS,
    FETCH_CERTIFICATE_FAILED,
} from './types';

//------------------------------------------------------
//Certificate


export const fetchCertificate = payload => {
    return { type: FETCH_CERTIFICATE, payload };
};

export const fetchCertificateSuccess = payload => {
    return { type: FETCH_CERTIFICATE_SUCCESS, payload };
};

export const fetchCertificateFailed = payload => {
    return { type: FETCH_CERTIFICATE_FAILED, payload };
};
