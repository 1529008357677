const certificateView = {
    certificate: {
        english: 'Certificate - Vaccination against COVID-19',
        sinhala: 'සහතිකය - COVID-19 ට එරෙහිව එන්නත් කිරීම',
        tamil: 'சான்றிதழ் - COVID-19 க்கு எதிரான தடுப்பூசி',
    },
    viewRegistrations: {
        english: 'View Registrations',
        sinhala: 'ලියාපදිංචි කිරීම් බලන්න',
        tamil: 'பதிவுகளைக் காண்க',
    },
    download: {
        english: 'Download',
        sinhala: 'බාගත කරන්න',
        tamil: 'பதிவிறக்க',
    },
    asImage: {
        english: 'As Image',
        sinhala: 'රූපය ලෙස',
        tamil: 'ஒரு படமாக',
    },
    asPdf: {
        english: 'As PDF',
        sinhala: 'PDF ලෙස',
        tamil: 'PDF ஆக',
    },
    
};

export default certificateView;