const confirmation = {
    registrationSummary: {
        english: 'Registration Summary',
        sinhala: 'ලියාපදිංචි සාරාංශය',
        tamil: 'பதிவு சுருக்கம்',
    },
    nic: {
        english: 'NIC',
        sinhala: 'ජාතික හැඳුනුම්පත',
        tamil: 'தே. அ. அ',
    },
    name: {
        english: 'Name',
        sinhala: 'නම',
        tamil: 'பெயர்',
    },
    gender: {
        english: 'Gender',
        sinhala: 'ස්ත්‍රී පුරුෂ භාවය',
        tamil: 'பாலினம்',
    },
    dob: {
        english: 'Date of Birth',
        sinhala: 'උපන් දිනය',
        tamil: 'பிறந்த திகதி',
    },
    firstVaccine: {
        english: 'Received Second Vaccine?',
        sinhala: 'දෙවන එන්නත ලැබුණි ?',
        tamil: 'இரண்டாவது தடுப்பூசி பெற்றது ?',
    },
    centerDetails: {
        english: 'Center Details',
        sinhala: 'මධ්‍යස්ථාන විස්තර',
        tamil: 'நிலைய விபரங்கள்',
    },
    privacyPolicy : {
        english: 'Privacy Policy',
        sinhala: 'රහස්‍යතා ප්‍රතිපත්‍යය',
        tamil: 'தனியுரிமைக் கொள்கை',
    },
    iAgree : {
        english: 'I have read and accept the Privacy Statement.',
        sinhala: 'මම රහස්‍යතා ප්‍රකාශය කියවා පිළිගෙන ඇත.',
        tamil: 'தனியுரிமை அறிக்கையை நான் படித்து ஏற்றுக்கொண்டேன்.',
    },
    warningMsg:{
        english: 'If you are not a resident of this Grama Niladari area or if you are not a registered citizen in this Grama Niladari area, you will not be eligible for vaccination at this center. In case your NIC residential address is not within this Grama Niladari area, you will be required to provide a billing proof to prove your residency in that Grama Niladari area.',
        sinhala: 'ඔබ මෙම ග්‍රාම නිලදාරි ප්‍රදේශයේ පදිංචිකරුවෙකු නොවේ නම් හෝ ඔබ මෙම ග්‍රාම නිලදාරි ප්‍රදේශයේ ලියාපදිංචි පුරවැසියෙකු නොවේ නම්, ඔබට මෙම මධ්‍යස්ථානයේ එන්නත ලබා ගැනීමට සුදුසුකම් නොලැබේ. ඔබේ ජාතික හැඳුනුම්පතෙහි පදිංචි ලිපිනය මෙම ග්‍රාම නිලදාරි ප්‍රදේශය තුළ නොමැති නම්, එම ග්‍රාම නිලදාරි ප්‍රදේශයේ ඔබ පදිංචිව සිටින බව සනාථ කිරීම සඳහා බිල්පතක සාක්ෂියක් ඉදිරිපත් කිරීම අවශ්‍ය වේ.',
        tamil: 'நீங்கள் இந்த கிராம நிலதாரி பகுதியில் வசிப்பவராக இல்லாவிட்டால் அல்லது இந்த கிராம நிலதாரி பகுதியில் பதிவுசெய்யப்பட்ட குடிமகனாக இல்லாவிட்டால், இந்த மையத்தில் தடுப்பூசி போட நீங்கள் தகுதி பெற மாட்டீர்கள். உங்கள் என்.ஐ.சி குடியிருப்பு முகவரி இந்த கிராம நிலதாரி பகுதிக்குள் இல்லையென்றால், அந்த கிராம நிலாதரி பகுதியில் உங்கள் வதிவிடத்தை நிரூபிக்க பில்லிங் ஆதாரத்தை நீங்கள் வழங்க வேண்டும்.',
    },
    districtName:{
        english: 'District',
        sinhala: 'දිස්ත්‍රික්කය',
        tamil: 'மாவட்டம்',
    },
    mohCenterName:{
        english: 'MOH Area',
        sinhala: 'MOH ප්‍රදේශය',
        tamil: 'MOH பகுதி',
    },
    locationName:{
        english: 'Vaccination Center',
        sinhala: 'එන්නත් කිරීමේ මධ්‍යස්ථානය',
        tamil: 'தடுப்பூசி மையம்',
    },
    vaccineFirstDoseDate: {
        english: 'Second Dose Date',
        sinhala: 'දෙවන මාත්‍රා දිනය',
        tamil: 'இரண்டாவது டோஸ் தேதி',
    },
    vaccineType: {
        english: 'Vaccine Type',
        sinhala: 'එන්නත් වර්ගය',
        tamil: 'தடுப்பூசி வகை',
    }
};

export default confirmation;
