import {
    REQUEST_OTP,
    REQUEST_OTP_SUCCESS,
    REQUEST_OTP_FAILED,
    OTP_ENTERED,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REQUEST_AVAILABLE_CENTER,
    REQUEST_AVAILABLE_CENTER_SUCCESS,
    REQUEST_AVAILABLE_CENTER_FAILED,
} from '../actions/types';

const initialState = {
    requestedOTP: false,
    loading: false,
    error: undefined,
    loginSuccess: false,
    otpRequestedTime: null,
    mobile: '',
    otp: '',
    centerList : []
};

const signinReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_OTP:
            return {
                ...state,
                loading: true,
                mobile: action.payload,
            };
        case REQUEST_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                requestedOTP: true,
                otpRequestedTime: new Date().valueOf(),
            };
        case REQUEST_OTP_FAILED:
            return {
                ...state,
                loading: false,
                error: 'sendingOtpFailedError',
            };
        case OTP_ENTERED:
            return {
                ...state,
                loading: true,
                otp: action.payload.otp,
            };

        case LOGIN_SUCCESS:
            sessionStorage.setItem('mobile', state.mobile.mobile);
            sessionStorage.setItem('loginSuccess', true);
            sessionStorage.setItem('loginSuccessTime', new Date().valueOf());
            console.log(action.payload);
            sessionStorage.setItem('token', action.payload?.key?.token);
            return {
                ...state,
                loginSuccess: true,
            };

        case LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                error: 'otpValidationFailedError',
                loginSuccess: false,
            };

        case LOGOUT_SUCCESS:
            sessionStorage.clear();
            return {
                ...state,
                loading: false,
                loginSuccess: false,
            };

        case REQUEST_AVAILABLE_CENTER:
            return {
                ...state,
                loading: false,
                centerList: [],
            };

        case REQUEST_AVAILABLE_CENTER_SUCCESS:
            return {
                ...state,
                loading: false,
                centerList: action.payload.data,
            };

        case REQUEST_AVAILABLE_CENTER_FAILED:
            return {
                ...state,
                loading: false,
                centerList: [],
            };
        default:
            return state;
    }
};

export default signinReducer;
