import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import languageText from 'languages/basicRegistration';

import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';
import {fetchVaccineType} from '../../../actions/registration';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '-webkit-fill-available',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    warningMsg: {
        textAlign: "justify",
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        backgroundColor: '#fff7f7',
        lineHeight: '1.5rem',
    }
}));

const oldNICRegex = new RegExp('^[1-9][0-9]{8}[X,V,x,v]$');
const newNICRegex = new RegExp('^[18,19,20,21]{2}[0-9]{10}$');

const Registration = ({ language, VaccineTypeList, fetchVaccineType }) => {
    const classes = useStyles();

    useEffect(() => {
        fetchVaccineType();
    },[fetchVaccineType])

    const [gender, setGender] = React.useState(
        sessionStorage.getItem('gender') ? sessionStorage.getItem('gender') : ''
    );
    const [nic, setNic] = React.useState(
        sessionStorage.getItem('nic') ? sessionStorage.getItem('nic') : ''
    );
    const [isValidNic, setIsValidNic] = React.useState(true);

    const [fullName, setFullName] = React.useState(
        sessionStorage.getItem('fullName')
            ? sessionStorage.getItem('fullName')
            : ''
    );
    const [dob, setDob] = React.useState(sessionStorage.getItem('dob'));

    const handleGenderChange = event => {
        let tempGender = event.target.value;
        setGender(tempGender);
        sessionStorage.setItem('gender', tempGender);
    };

    const [vaccineType, setVaccineType] = React.useState(
        sessionStorage.getItem('vaccineType') ? sessionStorage.getItem('vaccineTypeId') : ''
    );

    const handleNicChange = event => {
        let tempNic = event.target.value;
        setNic(tempNic);
        setIsValidNic(oldNICRegex.test(tempNic) || newNICRegex.test(tempNic));
        sessionStorage.setItem('nic', tempNic);
        if(dob === null){
            changeDobAndGenderUsingId(tempNic)
        }
    }

    const changeDobAndGenderUsingId = tempNic =>{
        let mon =
        {
            "1": ["Jan", 31],
            "2": ["Feb", 29],
            "3": ["Mar", 31],
            "4": ["Apr", 30],
            "5": ["May", 31],
            "6": ["Jun", 30],
            "7": ["Jul", 31],
            "8": ["Aug", 31],
            "9": ["Sep", 30],
            "10": ["Oct", 31],
            "11": ["Nov", 30],
            "12": ["Dec", 31]
        };
        if (tempNic.length >= 5) {
            let year = tempNic.length===12 && tempNic.startsWith("19") ? tempNic.substr(0, 4) : "19" + tempNic.substr(0, 2);
            let days = tempNic.length===12 && tempNic.startsWith("19") ? tempNic.substr(4, 3) :parseInt(tempNic.substr(2, 3));
            let tempGender;
            if (days > 500) {
                tempGender = "female";
                days = days - 500;
            }
            else {
                tempGender = "male";
            }
            setGender(tempGender)
            sessionStorage.setItem('gender', tempGender);
            let key;
            for (key in mon) {
                if (days > mon[key][1]) {
                    days = days - mon[key][1];
                }
                else {
                    break;
                }
            }
            if (days < 10) {
                days = "0" + days;
            } 

            let month = mon[key][0];
            let dob = new Date(year + "-" +month + "-" +days);

            if(dob !== "Invalid date"){
                setDob(dob)
                sessionStorage.setItem('dob', dob);
            }
        }
    }

    const handleFullNameChange = event => {
        let tempFullName = event.target.value;
        setFullName(tempFullName);
        sessionStorage.setItem('fullName', tempFullName);
    };

    const handleDobChange = date => {
        setDob(date);
        sessionStorage.setItem('dob', date);
    };

    const [firstVaccine, setFirstVaccine] = React.useState(
        sessionStorage.getItem('firstVaccine')
            ? sessionStorage.getItem('firstVaccine')
            : null//'no'
    );

    const [firstVaccineDate, setFirstVaccineDate] = React.useState(
        sessionStorage.getItem('firstVaccineDate')
    );

    const handleFirstVaccineChange = event => {
        let tempFirstVaccine = event.target.value;
        setFirstVaccine(tempFirstVaccine);
        const todayDate = new Date();
        setFirstVaccineDate(todayDate);
        sessionStorage.setItem('firstVaccineDate', todayDate);
        sessionStorage.setItem('firstVaccine', tempFirstVaccine);
    };

    const handleFirstVaccineDate = date => {
        let tempFirstVaccineDate = date;
        setFirstVaccineDate(tempFirstVaccineDate);
        sessionStorage.setItem('firstVaccineDate', tempFirstVaccineDate);
    };

    const handleChangeVaccineType = (event,value) => {
        let tempValue = event.target.value;
        const selected = VaccineTypeList.filter(res => res.id === tempValue)[0];
        setVaccineType(selected.id || '');
        sessionStorage.setItem('vaccineType', selected);
        sessionStorage.setItem('vaccineTypeId', selected.id);
        sessionStorage.setItem('vaccineTypeName', selected.name);
    };

    let dateBefore18Years = new Date();
    dateBefore18Years.setFullYear(dateBefore18Years.getFullYear() - 18);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className={classes.privacyPolicy}>
                    <Alert severity="warning" className={classes.warningMsg}>{languageText.warningMsg[language]}</Alert>
                </Grid>
            </Grid>
            <br></br>
            <Typography variant="h6" gutterBottom>
                {languageText.userDetails[language]}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        required
                        value={nic}
                        id="nic"
                        name="nic"
                        label={languageText.nic[language]}
                        fullWidth
                        autoComplete="nic"
                        helperText={languageText.nicHelperText[language]}
                        onChange={handleNicChange}
                        error={!isValidNic}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        value={fullName}
                        id="name"
                        name="name"
                        label={languageText.fullName[language]}
                        fullWidth
                        autoComplete="name"
                        onChange={handleFullNameChange}
                    />
                </Grid>
                {/* <Grid> */}

                <Grid item xs={12} sm={8}>
                    <FormControl className={classes.formControl} style={{width: '60%'}}> 
                        <InputLabel id="demo-simple-select-label">
                            {languageText.selectGender[language]}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={gender}
                            onChange={handleGenderChange}
                        >
                            <MenuItem value={'male'}>
                                {languageText.male[language]}
                            </MenuItem>
                            <MenuItem value={'female'}>
                                {languageText.female[language]}
                            </MenuItem>
                            <MenuItem value={'other'}>
                                {languageText.other[language]}
                            </MenuItem>
                        </Select>
                     </FormControl> 
                </Grid>
                <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                         <KeyboardDatePicker
                           // disableToolbar
                            variant="inline"
                            margin="normal"
                            id="date-picker-inline"
                            label={languageText.dob[language]}
                            format="dd-MM-yyyy"
                            value={dob}
                            onChange={handleDobChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk
                            emptyLabel={languageText.dob[language]}
                            maxDate={dateBefore18Years}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                {/* </Grid> */}
                {/* <Grid item xs={12}>
                    <FormControl className={classes.formControl}> 
                        <InputLabel id="demo-simple-select-label">
                            {languageText.selectVaccineType[language]}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="vaccineType-select"
                            value={vaccineType}
                            onChange={handleChangeVaccineType}
                        >
                            {VaccineTypeList.map(type => (
                                <MenuItem key={type.id} value={type}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                     </FormControl> 
                </Grid> */}
            </Grid>
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
                {languageText.firstVaccineReceived[language]}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="vaccine"
                            name="vaccine"
                            value={firstVaccine}
                            onChange={handleFirstVaccineChange}
                        >
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label={languageText.yes[language]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label={languageText.no[language]}
                                />
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {firstVaccine === 'yes' ? (
                        <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{width: '100%'}}>
                            <KeyboardDatePicker
                                maxDate={new Date()}
                                disableToolbar
                                variant="inline"
                                format="dd-MM-yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label={languageText.firstVaccineDate[language]}
                                value={firstVaccineDate}
                                onChange={handleFirstVaccineDate}
                                keyboardbuttonprops={{
                                    'aria-label': 'change date',
                                }}
                                autoOk
                                emptyLabel={
                                    languageText.firstVaccineDate[language]
                                }
                            />
                        </MuiPickersUtilsProvider>
                        {/* <br/>
                        <br/>
                        <FormControl className={classes.formControl} style={{width: '95%'}}> 
                            <InputLabel id="demo-simple-select-label">
                                {languageText.selectVaccineType[language]}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="vaccineType-select"
                                value={vaccineType}
                                onChange={handleChangeVaccineType}
                            >
                                {VaccineTypeList.map(type => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                         </FormControl> */}
                    </div>
                    ) : null}
                </Grid>
            </Grid>
        </div>
    );
};

function mapStateToProps({ signin, common, registration }) {
    let { requestedOTP, loading, error } = signin;
    let { language } = common;
    let { vaccineTypes } = registration;
    return {
        requestedOTP,
        loading: vaccineTypes.loading || loading,
        error,
        VaccineTypeList: vaccineTypes.vaccine,
        language: sessionStorage.getItem('language') || language,
    };
}

export default connect(mapStateToProps, {fetchVaccineType})(Registration);
