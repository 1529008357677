import axios from 'axios';

export default {
    otp: {
        requestOTP: payload =>
            axios.post(`/sendOTP`, 
             {
                mobileno : payload.mobileno,
                 nic: '',
               }, 
             {
                 headers: {
                    "captcha-response": payload.tempToken
                }
             }).then(res => res.data),
        verifyOtp: payload =>
            axios.post(`/otpValidation`, { ...payload }).then(res => res.data),
    },
    availableCenterList: {
        getAvailableCenters: () =>
            axios
                .get(`/vsession/list`)
                .then(res => res),
    },
};
